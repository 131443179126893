<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters


// Props

const props = defineProps({
    product: {
        type: String,
        required: true
    },
    location: {
        type: String,
        required: false
    },
    campaign: {
        type: String,
        required: false
    }
})

//////////////////
// Data Sources //
//////////////////

const product_metrics = ref({})
const chart_data = ref({})
const chart_data2 = ref({})
const locations = ref([])

const selected_location = ref(null)
const selected_campaign = ref(null)
const campaign_date_start = ref(null)
const campaign_date_end = ref(null)
const campaign_index = ref(0)

const chartOptions = ref({})

const get_product_metrics = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/product-metrics', {id: props.product})
    .then(response => {
        product_metrics.value = response.data
        console.log("product_metrics", product_metrics.value)
        if (Object.keys(response.data).length === 0){
            toast.add({severity:'error', summary: 'Error', detail: 'No data found for product ' + props.product, life: 3000})
            chart_data.value = {}
            campaign_date_start.value = null
            campaign_date_end.value = null
            chart_data2.value = {}
            loader.value = false
        } else {
            chart_data.value = {
                labels: product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].total.array_dates,
                datasets: [{data: product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].total.array_values,
                label: 'Sold units (All locations)',
                pointBorderColor: 'rgb(55, 81, 255)',
                pointBackgroundColor: 'rgb(55, 81, 255)',
                backgroundColor: 'rgba(55, 81, 255, 0.2)',
                borderColor: 'rgb(55, 81, 255)',
                fill: true,
                tension: 0.4}
            ]}
            campaign_date_start.value = product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].campaign_start_date
            campaign_date_end.value = product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].campaign_end_date
            chart_data2.value = {
                labels: product_metrics.value.years_stock[product_metrics.value.year_ids[0]].array_dates,
                datasets: [{data: product_metrics.value.years_stock[product_metrics.value.year_ids[0]].array_values,
                label: 'Stock burn down (All locations)',
                pointBorderColor: 'rgb(55, 81, 255)',
                pointBackgroundColor: 'rgb(55, 81, 255)',
                backgroundColor: 'rgba(100, 70, 170, 0.4)',
                borderColor: 'rgb(55, 81, 255)',
                fill: true,
                tension: 0.4}
            ]}
            if (props.location){
                selected_location.value = props.location
                change_location()
            }
            if (props.campaign){
                console.log("props.campaign", props.campaign)
                campaign_index.value = product_metrics.value.campaign_ids.indexOf(props.campaign)
                selected_campaign_id.value = props.campaign
                change_champaign(selected_campaign_id.value)
            } else {
                selected_campaign_id.value = product_metrics.value.campaign_ids[0]
            }
        }
        console.log("chart_data", chart_data.value)
        loader.value = false
    })
}

onMounted(() => {
    get_product_metrics()
    chartOptions.value = setChartOptions()
})

const selected_campaign_id = ref(null)
const change_champaign = (campaign_id) => {
    //get index of selected campaign
    selected_location.value = null
    selected_campaign_id.value = campaign_id
    campaign_index.value = product_metrics.value.campaign_ids.indexOf(campaign_id)
    chart_data.value = {
        labels: product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].total.array_dates,
        datasets: [{
            data: product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].total.array_values,
            label: 'Sold units (All locations)',
            pointBorderColor: 'rgb(55, 81, 255)',
            pointBackgroundColor: 'rgb(55, 81, 255)',
            backgroundColor: 'rgba(55, 81, 255, 0.2)',
            borderColor: 'rgb(55, 81, 255)',
            fill: true,
            tension: 0.4
        }]
    }
    campaign_date_start.value = product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].campaign_start_date
    campaign_date_end.value = product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].campaign_end_date
}

const change_year = (year_id) => {
    chart_data2.value = {
        labels: product_metrics.value.years_stock[year_id].array_dates,
        datasets: [{
            data: product_metrics.value.years_stock[year_id].array_values,
            label: 'Stock burn down (All locations)',
            pointBorderColor: 'rgb(55, 81, 255)',
            pointBackgroundColor: 'rgb(55, 81, 255)',
            backgroundColor: 'rgba(100, 70, 170, 0.4)',
            borderColor: 'rgb(55, 81, 255)',
            fill: true,
            tension: 0.4
        }]
    }
}

const change_location = () => {
    if (selected_location.value){
        chart_data.value = {
            labels: product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].locations[selected_location.value].array_dates,
            datasets: [{
                data: product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].locations[selected_location.value].array_values,
                label: 'Sold units (' + selected_location.value + ')',
                pointBorderColor: 'rgb(55, 81, 255)',
                pointBackgroundColor: 'rgb(55, 81, 255)',
                backgroundColor: 'rgba(55, 81, 255, 0.2)',
                borderColor: 'rgb(55, 81, 255)',
                fill: true,
                tension: 0.4
            }]
        }
        campaign_date_start.value = product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].campaign_start_date
        campaign_date_end.value = product_metrics.value.campaigns[product_metrics.value.campaign_ids[campaign_index.value]].campaign_end_date
        chart_data2.value = {
            labels: product_metrics.value.years_stock[product_metrics.value.year_ids[0]]["locations"][selected_location.value].array_dates,
            datasets: [{data: product_metrics.value.years_stock[product_metrics.value.year_ids[0]]["locations"][selected_location.value].array_values,
            label: 'Stock burn down (' + selected_location.value + ')',
            pointBorderColor: 'rgb(55, 81, 255)',
            pointBackgroundColor: 'rgb(55, 81, 255)',
            backgroundColor: 'rgba(100, 70, 170, 0.4)',
            borderColor: 'rgb(55, 81, 255)',
            fill: true,
            tension: 0.4}
        ]}
    } else {
        change_champaign(product_metrics.value.campaign_ids[campaign_index.value])
    }
}

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    }
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Copied to clipboard', detail: value + ' was copied to clipboard', life: 3000})
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

const format_number = (value) => {
    return Number(value).toLocaleString('de-CH');
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="grid">
            <div class="col-10">
                <div class="text-2xl font-bold mr-2 flex align-items-center">
                    {{ product_metrics["id"] }}
                    <i class="pi pi-copy cursor-pointer ml-2 text-primary" @click="copy_to_clipboard(product_metrics['description'])" v-tooltip.top="'Copy to clipboard'" />
                </div>
                <div class="text-2xl">{{ product_metrics["description"] }}</div>
            </div>
            <div class="col-2">
                <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + product_metrics['id'] + '.jpg'" class="w-5rem h-5rem shadow-2" v-tooltip.left="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + product_metrics['id'] + '.jpg\' />', escape: false}" />
            </div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Cost Unit</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ format_currency(product_metrics["cost_unit"]) }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Price Unit</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ format_currency(product_metrics["price_unit"]) }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Package size</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["Package size"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Zebra COGS</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ format_currency(product_metrics["Zebra COGS (LCY)"]) }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Main category</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["Main category"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Sub category</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["Sub category"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Product type</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["Product type"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Display type</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["Display type"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">VmLocation</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["VmLocation"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">VmModule</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["VmModule"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Comm. theme</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1">{{ product_metrics["Commercial theme"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50 flex align-items-center">Campaigns</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1 flex align-items-center">
                <Chip v-for="campaign in product_metrics['campaign_ids']" :key="campaign" :label="String(campaign)" @click="change_champaign(campaign)" class="mr-2 text-sm cursor-pointer text-white hover:bg-primary hover:text-black-alpha-90" :class="campaign == selected_campaign_id ? 'bg-green-700' : 'bg-black-alpha-90'" icon="pi pi-megaphone" />
            </div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Campaign start</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1"><span v-if="campaign_date_start">{{ format_swiss_date(campaign_date_start) }}</span></div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Campaign end</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1"><span v-if="campaign_date_end">{{ format_swiss_date(campaign_date_end) }}</span></div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50 flex align-items-center">Locations</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1">
                <Dropdown @change="change_location()" v-if="product_metrics['locations_dropdown']" v-model="selected_location" showClear filter :options="product_metrics['locations_dropdown']" optionLabel="name" optionValue="id" placeholder="Select a location" class="text-sm w-full" />
            </div>
            <div class="col-12">
                <Chart type="line" :data="chart_data" :options="chartOptions" class="h-30rem w-full" />
            </div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50 flex align-items-center">Years</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1 flex align-items-center">
                <Chip v-for="year in product_metrics['year_ids']" :key="year" :label="String(year)" @click="change_year(year)" class="mr-2 text-sm cursor-pointer bg-black-alpha-90 text-white hover:bg-primary hover:text-black-alpha-90" icon="pi pi-calendar" />
            </div>
            <div class="col-12">
                <Chart type="line" :data="chart_data2" :options="chartOptions" class="h-30rem w-full" />
            </div>
        </div>
    </BlockUI>
</template>