<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'

import ProductMetrics from '@/customers/flyingtiger/components/subcomponents/FlyingTiger-ProductMetrics.vue'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const page_loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_campaigns = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_campaigns_search = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const campaigns = ref([])
const campaign = ref({"items": []})
const selected_campaign = ref(null)
const new_campaign = ref({})
const campaign_search_data = ref({"performance": []})
const selected_campaign_search = ref(null)

const product_infos = ref({})

const selected_xls_file = ref(null)
const selected_xls_file_name = ref(null)
const fileUpload = ref(null)
const selected_products = ref([])
const product_purchase_metrics = ref([])

const expanded_campaign_products = ref([])
const new_campaign_dialog = ref(false)
const edit_campaign_dialog = ref(false)
const product_infos_slider = ref(false)
const product_purchase_infos_slider = ref(false)
const purchase_slider = ref(false)
const price_calculator_slider = ref(false)
const view_store_budgets_dialog = ref(false)
const view_campaign_report = ref(false)


const locations = ref([])

const price_calculator_items = ref([])
const show_store_budgets = ref(false)

onMounted( async () => {
    page_loader.value = true
    get_campaigns()
    get_locations()
    if (localStorage.getItem('selected_campaign')) {
        selected_campaign.value = localStorage.getItem('selected_campaign')
        get_campaign()
    }else{
        page_loader.value = false
    }
})

const get_campaigns = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-campaigns')
    .then(response => {
        campaigns.value = response.data
        loader.value = false
    })
}

const get_campaign = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-campaign', {id: selected_campaign.value})
    .then(response => {
        console.log("get_campaign", response.data)
        campaign.value = response.data
        if (response.data == null){
            selected_campaign.value = campaigns.value[0].id
            localStorage.setItem('selected_campaign', selected_campaign.value)
            get_campaign()
        }
        if (campaign.value.purchase_mode == null){
            campaign.value.purchase_mode = true
        }
        if (campaign.value.purchase_mode == true){
            items_with_status_not_done()
            items_with_status_purchase_not_done()
        }
        console.log("campaign", campaign.value)
        loader.value = false
        page_loader.value = false
    })
}

const get_campaign_search = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-campaign', {id: selected_campaign_search.value})
    .then(response => {
        console.log("campaign search", response.data)
        campaign_search_data.value = response.data
        loader.value = false
    })
}

const select_campaign = () => {
    localStorage.setItem('selected_campaign', selected_campaign.value)
    get_campaign()
}

const select_campaign_search = () => {
    get_campaign_search()
}

const get_locations = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-locations')
    .then(response => {
        locations.value = response.data
        loader.value = false
    })
}

const onSelectFile = (event) => {
    let reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
        selected_xls_file.value = reader.result
    }
    selected_xls_file_name.value = event.target.files[0]
}

const create_new_campaign = async () => {
    loader.value = true
    let payload = {
        "file": selected_xls_file.value,
        "filename": selected_xls_file_name.value.name
    }
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/create-new-campaign", payload)
        .then(response => {
                new_campaign_dialog.value = false
                toast.add({severity:'success', summary: 'Purchase created', detail: 'The purchase was successfully created', life: 3000})
                get_campaigns()
                loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const product_sku = ref(null)
const selected_location_prop = ref(null)
const selected_campaign_prop = ref(null)
const show_product_infos = (e, location = null, campaign = null) => {
    if (location){
        selected_location_prop.value = location
    }
    if (campaign){
        selected_campaign_prop.value = campaign
    }
    product_infos.value = e.data
    if (product_infos.value["item_no"]){
        product_sku.value = product_infos.value["item_no"]
    } else if (product_infos.value["Item no."]){
        product_sku.value = product_infos.value["Item no."]
    } else if (product_infos.value["id"]){
        product_sku.value = product_infos.value["id"]
    }
    product_infos_slider.value = true
}

const product_summary_infos = ref({})
const show_purchase_product_infos = (e) => {
    product_summary_infos.value = e.data
    product_sku.value = product_summary_infos.value["Item no."]
    product_purchase_infos_slider.value = true
}

const selected_match_campaign_purchase_item_no = ref(null)
const apply_purchase_recommendations = (campaign_id, index, selected_item, item_no) => {
    loader.value = true
    selected_match_campaign.value = campaign_id
    selected_match_campaign_index.value = index
    selected_match_campaign_item.value = selected_item
    selected_match_campaign_purchase_item_no.value = item_no
    for (let key in locations.value){
        if (campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total"] == 0){
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total"] = Math.round(product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase / product_purchase_metrics.value[selected_match_campaign_index.value]['Package size'])
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total_units"] = product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total_cost"] = product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase * product_purchase_metrics.value[selected_match_campaign_index.value]['Zebra COGS (LCY)']
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total_price"] = product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase * product_purchase_metrics.value[selected_match_campaign_index.value]['price_unit']
        }
        if (campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total"] == 0){
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["flag"] = true
        } else {
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["flag"] = false
        }
    }
    loader.value = false
}

const overwrite_purchase_amounts = () => {
    loader.value = true
    for (let key in locations.value){
        campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total"] = Math.round(product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase / product_purchase_metrics.value[selected_match_campaign_index.value]['Package size'])
        campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total_units"] = product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase
        campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total_cost"] = product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase * product_purchase_metrics.value[selected_match_campaign_index.value]['Zebra COGS (LCY)']
        campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total_price"] = product_purchase_metrics.value[selected_match_campaign_index.value].campaigns[selected_match_campaign.value].locations[locations.value[key]["id"]].recommended_purchase * product_purchase_metrics.value[selected_match_campaign_index.value]['price_unit']
        campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["flag"] = false
        if (campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["total"] == 0){
            campaign.value["purchase"][selected_match_campaign_purchase_item_no.value][locations.value[key]["id"]]["flag"] = true
        }
    }
    loader.value = false
}

const change_purchase_status = (item_no, status) => {
    loader.value = true
    console.log("change_purchase_status", item_no, status)
    for (let key in campaign.value["items"]){
        if (campaign.value["items"][key]["Item no."] == item_no){
            campaign.value["items"][key]["purchase_status"] = status
            toast.add({severity:'success', summary: 'Purchase status was successfully changed', life: 3000})
            save_campaign()
            loader.value = false
        }
    }
}

const count_flags = (item_no) => {
    let count = 0
    for (let key in locations.value){
        if (campaign.value["purchase"][item_no][locations.value[key]["id"]]["flag"] == true){
            count++
        }
    }
    return count
}

const purchase_totals = (item_no) => {
    let total = 0
    for (let key in locations.value){
        // check if keys exist
        if (campaign.value["purchase"][item_no]){
            if (campaign.value["purchase"][item_no][locations.value[key]["id"]]){
                if (campaign.value["purchase"][item_no][locations.value[key]["id"]]["total"]){
                    total += campaign.value["purchase"][item_no][locations.value[key]["id"]]["total"]
                }
            }
        }
    }
    return total
}

const purchase_units_totals = (item_no) => {
    let total = 0
    for (let key in locations.value){
        if (campaign.value["purchase"][item_no][locations.value[key]["id"]]["total_units"]){
            total += campaign.value["purchase"][item_no][locations.value[key]["id"]]["total_units"]
        }
    }
    return total
}

const purchase_cost_totals = (item_no) => {
    let total = 0
    for (let key in locations.value){
        if (campaign.value["purchase"][item_no]){
            if (campaign.value["purchase"][item_no][locations.value[key]["id"]]){
                if (campaign.value["purchase"][item_no][locations.value[key]["id"]]["total_cost"]){
                    total += campaign.value["purchase"][item_no][locations.value[key]["id"]]["total_cost"]
                }
            }
        }
    }
    return total
}

const purchase_sale_totals = (item_no) => {
    let total = 0
    for (let key in locations.value){
        if (campaign.value["purchase"][item_no]){
            if (campaign.value["purchase"][item_no][locations.value[key]["id"]]){
                if (campaign.value["purchase"][item_no][locations.value[key]["id"]]["total_price"]){
                    total += campaign.value["purchase"][item_no][locations.value[key]["id"]]["total_price"]
                }
            }
        }
    }
    return total
}

const store_purchase_total = (store_id) => {
    let total = 0
    for (let key in locations.value){
        if (campaign.value["purchase"]){
            for (let key2 in campaign.value["purchase"]){
                if (campaign.value["purchase"][key2][store_id]){
                    if (campaign.value["purchase"][key2][store_id]["total_cost"]){
                        total += campaign.value["purchase"][key2][store_id]["total_cost"]
                    }
                }
            }
        }
    }
    return total
}

const references_search_slider = ref(false)
const item_index = ref(0)
const search_references = () => {
    search_reference(product_sku.value)
    references_search_slider.value = true
}

const item_index_purchase = ref(0)
const purchase_amounts = () => {
    items_with_status_purchase_not_done()
    product_purchase_metrics.value = []
    get_matches()
    if (!campaign.value["purchase"]) {
        campaign.value["purchase"] = {}
    }
    for (let key in items_todo_purchase.value) {
        if (!campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]]){
            campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]] = {}
        }
        for (let key2 in locations.value){
            if (!campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]]){
                campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]] = {}
                campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]]["total"] = 0
                campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]]["flag"] = false
                campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]]["total_units"] = 0
                campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]]["total_cost"] = 0
                campaign.value["purchase"][items_todo_purchase.value[key]["Item no."]][locations.value[key2]["id"]]["total_price"] = 0
            }
        }
    }
    console.log("updated purchase", campaign.value)
    purchase_slider.value = true
}

const selected_match_campaign = ref(null)
const selected_match_campaign_index = ref(null)
const selected_match_campaign_item = ref(null)
const get_matches = async () => {
    product_purchase_metrics.value = []
    selected_match_campaign.value = null
    selected_match_campaign_index.value = null
    selected_match_campaign_item.value = null
    for (let index in items_todo_purchase.value[item_index_purchase.value]["matches"]){
        await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/product-metrics', {id: items_todo_purchase.value[item_index_purchase.value]["matches"][index]})
        .then(response => {
            product_purchase_metrics.value.push(response.data)
        })
    }
    console.log("product_purchase_metrics", product_purchase_metrics.value)
}

watch (item_index, (currentValue, oldValue) => {
    search_reference(items_with_status_not_done())
})

watch (item_index_purchase, (currentValue, oldValue) => {
    get_matches()
})

const similar_items = ref([])
const search_loader = ref(false)
const search_reference = (manual_item = null) => {
    loader.value = true
    search_loader.value = true
    let item_no = null
    if (manual_item) {
        item_no = manual_item
    } else {
        item_no = items_with_status_not_done()
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/search-references', {id: item_no})
    .then(response => {
        similar_items.value = response.data
        loader.value = false
        search_loader.value = false
    })
}
const search_term = ref(null)
const search_reference_manual = () => {
    loader.value = true
    search_loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/search-references-manual', {search_term: search_term.value})
    .then(response => {
        similar_items.value = response.data
        loader.value = false
        search_loader.value = false
    })
}

const search_reference_manual_item = (item_no) => {
    product_purchase_infos_slider.value = false
    references_search_slider.value = true
    item_index.value = items_todo.value.findIndex(item => item["Item no."] == item_no)
    search_reference(items_with_status_not_done())
}

const search_purchase_manual_item = (item_no) => {
    purchase_slider.value = false
    item_index_purchase.value = items_todo_purchase.value.findIndex(item => item["Item no."] == item_no)
    purchase_amounts()
}

const items_todo = ref([])
const items_with_status_not_done = () => {
    items_todo.value = campaign.value['items'].filter(item => item["reference_status"] === false)
    if (items_todo.value.length > 0){
        return items_todo.value[item_index.value]["Item no."]
    } else {
        return null
    }
}

const items_todo_purchase = ref([])
const items_with_status_purchase_not_done = () => {
    items_todo_purchase.value = campaign.value['items'].filter(item => item["purchase_status"] === false && item["reference_status"] === true)
    for (let key in items_todo_purchase.value){
        if (!items_todo_purchase.value[key]["purchase"]){
            items_todo_purchase.value[key]["purchase"] = {}
        }
    }
    if (items_todo_purchase.value.length > 0){
        return items_todo_purchase.value[item_index_purchase.value]["Item no."]
    } else {
        return null
    }
}

const edit_campaign = () => {
    if (campaign.value["purchase_budget"] == null){
        campaign.value["purchase_budget"] = 0
    }
    if (campaign.value["store_budgets"] == null){
        campaign.value["store_budgets"] = {}
        for (let key in locations.value){
            campaign.value["store_budgets"][locations.value[key]["id"]] = 0
        }
    }
    edit_campaign_dialog.value = true
}

const remove_reference = async (item_no, reference) => {
    console.log("remove_reference", item_no)
    confirm.require({
        message: 'Are you sure you want to remove this reference?',
        header: 'Remove reference?',
        rejectLabel: 'No',
        acceptLabel: 'Yes, remove it',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            loader.value = true
            for (let key in campaign.value["items"]){
                if (campaign.value["items"][key]["Item no."] == item_no){
                    for (let key2 in campaign.value["items"][key]["matches"]){
                        if (campaign.value["items"][key]["matches"][key2] == reference){
                            campaign.value["items"][key]["matches"].splice(key2, 1)
                            toast.add({severity:'success', summary: 'Reference was successfully removed', life: 3000})
                            save_campaign()
                            loader.value = false
                            break
                        }
                    }
                }
            }
        }
    })
}

const add_reference = async (item_no, reference) => {
    console.log("add_reference", item_no)
    loader.value = true
    for (let key in campaign.value["items"]){
        if (campaign.value["items"][key]["Item no."] == item_no){
            if (campaign.value["items"][key]["matches"].includes(reference)){
                toast.add({severity:'error', summary: 'Reference already exists', life: 3000})
                loader.value = false
            } else {
                campaign.value["items"][key]["matches"].push(reference)
                toast.add({severity:'success', summary: 'Reference was successfully added', life: 3000})
                save_campaign()
                loader.value = false
            }
        }
    }
}

const change_reference_status = async (item_no, status) => {
    console.log("change_reference_status", item_no)
    loader.value = true
    for (let key in campaign.value["items"]){
        if (campaign.value["items"][key]["Item no."] == item_no){
            campaign.value["items"][key]["reference_status"] = status
            toast.add({severity:'success', summary: 'Reference status was successfully changed', life: 3000})
            save_campaign()
            loader.value = false
        }
    }
}

const save_campaign = async () => {
    loader.value = true
    calculate_purchase_totals()
    // update purchase numbers:
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/save-campaign", {"id": selected_campaign.value, "campaign": campaign.value})
        .then(response => {
            get_campaign()
            toast.add({severity:'success', summary: 'Campaign was successfully saved', life: 3000})
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const campaign_report_data = ref({})
const campaign_report = (event) => {
    console.log(event.data)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/campaign-report", {id: event.data.id})
        .then(response => {
            campaign_report_data.value = response.data
            console.log("campaign_report", campaign_report_data.value)
            view_campaign_report.value = true
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const calculate_purchase_totals = () => {
    loader.value = true
    console.log("recalculating purchase totals...")
    let counter = 0
    for (let key in campaign.value["items"]){
        if (campaign.value["items"][key]["purchase_status"] == true){
            for (let key2 in locations.value){
                if (campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]){
                    if (campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total"] > 0){
                        counter++
                        campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total_units"] = campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total"] * campaign.value["items"][key]["Package size"]
                        campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total_cost"] = campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total_units"] * Number(campaign.value["items"][key]["Zebra COGS (LCY)"].toFixed(2))
                        campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total_price"] = campaign.value["purchase"][campaign.value["items"][key]["Item no."]][locations.value[key2]["id"]]["total_units"] * campaign.value["items"][key]["Recommended sales price (LCY)"]    
                    }
                }
            }
        }
    }
    loader.value = false
    console.log("recalculating purchase totals done: " + String(counter) + " items recalculated")
}

const export_campaign_excel = () => {
    open_url(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/export-campaign-excel?id=" + selected_campaign.value)
}

const export_campaign_report_excel = () => {
    open_url(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/export-campaign-report-excel?id=" + selected_campaign.value)
}

const add_product_to_price_change = (item_no) => {
    if (selected_products.value.includes(item_no)){
        selected_products.value.splice(selected_products.value.indexOf(item_no), 1)
        toast.add({severity:'success', summary: 'Product was successfully removed', life: 3000})
    } else {
        selected_products.value.push(item_no)
        toast.add({severity:'success', summary: 'Product was successfully added', life: 3000})
    }
}

const calculate_repricing = () => {
    console.log(selected_products.value)
    price_calculator_items.value = []
    for (let key in selected_products.value){
        for (let key2 in campaign.value["performance"]){
            if (campaign.value["performance"][key2]["item_no"] == selected_products.value[key]){
                campaign.value["performance"][key2]["new_price"] = campaign.value["performance"][key2]["price_unit"]
                price_calculator_items.value.push(campaign.value["performance"][key2])
                toast.add({severity:'success', summary: 'Product was successfully added', life: 3000})
            }
        }
    }
    console.log("calc_items", price_calculator_items.value)
    price_calculator_slider.value = true
}

const calculate_totals = (items) => {
    let total_new_price = 0
    let total_price = 0
    let total_cost_price = 0
    items.forEach(element => {
        total_new_price += (element["new_price"] * element["total_purchase_quantity"])
        total_price += (element["price_unit"] * element["total_purchase_quantity"])
        total_cost_price += (element["cost_unit"] * element["total_purchase_quantity"])
    })
    console.log("calculating totals...", total_new_price, total_price, total_cost_price, total_new_price - total_cost_price)
    return total_new_price - total_cost_price
}

const calculate_totals_percent = (items) => {
    let total_new_price = 0
    let total_price = 0
    let total_cost_price = 0
    items.forEach(element => {
        total_new_price += (element["new_price"] * element["total_purchase_quantity"])
        total_price += (element["price_unit"] * element["total_purchase_quantity"])
        total_cost_price += (element["cost_unit"] * element["total_purchase_quantity"])
    })
    console.log("calculating totals in percent...", total_new_price, total_price, total_cost_price, Math.round(total_new_price / total_price * 100))
    return Math.round(total_new_price / total_price * 100)
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Copied to clipboard', detail: value + ' was copied to clipboard', life: 3000})
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

const format_number = (value) => {
    return Number(value).toLocaleString('de-CH');
}

function Percentage(part, whole) {
    if (whole === 0) {
        return 0 // Avoid division by zero
    }
    // check if numbers are negatives and convert to positives
    if (part < 0) {
        part = part * -1
    }
    if (whole < 0) {
        whole = whole * -1
    }
    const percentage = (part / whole) * 100
    return Math.round(percentage)
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader || page_loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div>
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_campaigns['global'].value" placeholder="Suche" style="width: 400px" />
                    </span>
                    <Button label="New campaign" @click="new_campaign = {}, selected_xls_file = null, selected_xls_file_name = null, new_campaign_dialog = true" icon="pi pi-plus" class="mr-2" />
                    <Dropdown filter v-model="selected_campaign" optionLabel="name" optionValue="id" :options="campaigns" placeholder="Select a campaign" class="mr-2" @change="select_campaign" style="width: 400px" />
                    <Button v-if="selected_campaign" icon="pi pi-pencil" @click="edit_campaign(selected_campaign)" class="mr-2" />
                </template>
                <template #end>
                    <Button v-if="selected_products" label="Calculate Prices" icon="pi pi-calculator" class="bg-purple-600 border-purple-700 text-white mr-2" :badge="String(selected_products.length)" @click="calculate_repricing()" />
                    <Button label="Report" v-tooltip.top="'Export Campaign Report'" icon="pi pi-file-excel" class="mr-2" @click="export_campaign_report_excel" />
                    <Button label="Campaign" v-tooltip.top="'Export Campaign Purchases'" icon="pi pi-file-excel" class="mr-2" @click="export_campaign_excel" />
                </template>
            </Toolbar>
        </div>
        <div class="mt-3" v-if="campaign['purchase_mode']">
            <Toolbar>
                <template #start>
                    <Button :disabled="items_todo.length == 0 ? true : false" label="Reference items" icon="pi pi-search" @click="search_references" class="mr-2 w-auto" :badge="String(items_todo.length)" badgeSeverity="contrast" />
                    <Button :disabled="items_todo_purchase.length == 0 ? true : false" label="Purchase amounts" icon="pi pi-calculator" @click="purchase_amounts" class="mr-2 w-auto" :badge="String(items_todo_purchase.length)" badgeSeverity="contrast" />
                    <span class="ml-4">CHF 0.00 / {{ format_currency(campaign.purchase_budget) }}</span>
                    <ProgressBar class="ml-2" style="width: 200px" :value="100" v-tooltip.top="{value: 'Status total purchase budget', escape: false}">0%</ProgressBar>
                    <Button class="ml-2" v-tooltip.top="'Store budgets'" icon="pi pi-building" @click="view_store_budgets_dialog = true" />
                </template>
            </Toolbar>
        </div>
        <div class="grid mt-3" v-if="!campaign['purchase_mode']">
            <div class="col-6">
                <ProgressBar :value="Percentage(campaign['campaign_active_products'], campaign['campaign_total_products'])">{{ campaign['campaign_active_products'] }} / {{ campaign['campaign_total_products'] }} active items ({{ Percentage(campaign['campaign_active_products'], campaign['campaign_total_products']) }}%)</ProgressBar>
            </div>
            <div class="col-6">
                <ProgressBar :value="Percentage(campaign['campaign_sales_quantity_total'], campaign['campaign_quanitity_total'])">{{ campaign['campaign_sales_quantity_total'] }} / {{ campaign['campaign_quanitity_total'] }} sold units ({{ Percentage(campaign['campaign_sales_quantity_total'], campaign['campaign_quanitity_total']) }}%)</ProgressBar>
            </div>
        </div>
        <!-- MONITORING MODE -->
        <div rounded class="overflow-hidden shadow-3 border-200 mt-3" v-if="!campaign['purchase_mode']">
            <DataTable v-model:filters="filter_campaigns" dataKey="item_no" :value="campaign['performance']" @row-click="show_product_infos" :rows="50" responsiveLayout="scroll" :rowHover="true" class="rounded">
                <Column style="width: 100px">
                    <template #body="slotProps">
                        <Button v-if="selected_products.indexOf(slotProps.data.item_no) == -1" icon="pi pi-plus" rounded @click="add_product_to_price_change(slotProps.data.item_no)" />
                        <Button v-if="selected_products.indexOf(slotProps.data.item_no) > -1" class="bg-orange-600 border-orange-600" icon="pi pi-minus" rounded @click="add_product_to_price_change(slotProps.data.item_no)" />
                    </template>
                </Column>
                <Column field="Campaign" header="Campaign" sortable style="width: 10%" />
                <Column field="item_no" header="Item no." sortable style="width: 10%">
                    <template #body="slotProps">
                        <span class="font-bold">{{ slotProps.data.item_no }}</span>
                    </template>
                </Column>
                <Column field="Item name" header="Item name" style="width: 40%" />
                <Column header="Runtime" sortable style="width: 20%">
                    <template #body="slotProps">
                        <ProgressBar :value="Percentage(slotProps.data.amount_of_days_in_sale, 90)" v-tooltip.top="{value: 'Start: ' + format_swiss_date(slotProps.data.start_date) + '<br />End: ' + format_swiss_date(slotProps.data.end_date) + '<br />Days left: ' + String(slotProps.data.amount_of_days_left), escape: false}"> {{ Percentage(slotProps.data.amount_of_days_in_sale, 90)  }}%</ProgressBar>
                    </template>
                </Column>
                <Column header="Sales" style="width: 20%">
                    <template #body="slotProps">
                        <ProgressBar :value="Percentage(slotProps.data.total_sales_quantity, slotProps.data.total_purchase_quantity)" v-tooltip.top="{value: 'Sold: ' + String(slotProps.data.total_sales_quantity) + '<br />Purchased: ' + String(slotProps.data.total_purchase_quantity) + '<br />Rest: ' + String(slotProps.data.purchase_quanitity_left), escape: false}"> {{ Percentage(slotProps.data.total_sales_quantity, slotProps.data.total_purchase_quantity) }}%</ProgressBar>
                    </template>
                </Column>
                <Column header="Days left" style="width: 10%">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.selling_out" v-tooltip.top="{value: 'Estimated days left: ' + String(slotProps.data.estimated_days_to_sell_out) + '<br />Estimated stock after campaign end: ' + String(slotProps.data.estimated_purchase_quanitity_at_end) + '<br />Estimated days after campaign end: ' + String(0), escape: false}" class="p-tag p-tag-success mr-2">{{slotProps.data.estimated_days_to_sell_out}}</span>
                        <span v-if="!slotProps.data.selling_out" v-tooltip.top="{value: 'Estimated days left: ' + String(slotProps.data.estimated_days_to_sell_out) + '<br />Estimated stock after campaign end: ' + String(slotProps.data.estimated_purchase_quanitity_at_end) + '<br />Estimated days after campaign end: ' + String(0), escape: false}" class="p-tag p-tag-danger mr-2">{{slotProps.data.estimated_days_to_sell_out}}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
        <!-- PURCHASING MODE -->
        <div rounded class="overflow-hidden shadow-3 border-200 mt-3" v-if="campaign['purchase_mode']">
            <DataTable v-model:filters="filter_campaigns" :value="campaign['items']" @row-click="show_purchase_product_infos" :rows="50" responsiveLayout="scroll" :rowHover="true" class="rounded" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} to {last} from {totalRecords}">
                <Column style="width: 100px">
                    <template #body="slotProps">
                        <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['Item no.'] + '.jpg'" class="w-3rem h-3rem shadow-2" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['Item no.'] + '.jpg\' />', escape: false}" />
                    </template>
                </Column>
                <Column field="Item no." header="Item no.">
                    <template #body="slotProps">
                        <span class="font-bold">{{ slotProps.data["Item no."] }}</span><i @click="copy_to_clipboard(slotProps.data['Item no.'])" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
                    </template>
                </Column>
                <Column field="Item name" header="Item name" />
                <Column field="Campaign" header="Campaign" hidden />
                <Column field="Package size" header="Package size" />
                <Column field="Recommended sales price (LCY)" header="Rec. sale price">
                    <template #body="slotProps">
                        <span>{{ format_currency(slotProps.data["Recommended sales price (LCY)"]) }}</span>
                    </template>
                </Column>
                <Column header="Ref.">
                    <template #body="slotProps">
                        <Chip v-if="!slotProps.data['reference_status']" icon="pi pi-times" :label="String(slotProps.data['matches'].length)" class="bg-red-600 text-white mr-2" />
                        <Chip v-if="slotProps.data['reference_status']" icon="pi pi-check" :label="String(slotProps.data['matches'].length)" class="bg-green-600 text-white mr-2" />
                    </template>
                </Column>
                <Column header="Purchase">
                    <template #body="slotProps">
                        <Chip v-if="!slotProps.data['purchase_status']" icon="pi pi-times" :label="String(purchase_units_totals(slotProps.data['Item no.'])) + ' units (' + String(purchase_totals(slotProps.data['Item no.'])) + ' packs) - '+ format_currency(purchase_cost_totals(slotProps.data['Item no.']))" class="bg-red-600 text-white mr-2" />
                        <Chip v-if="slotProps.data['purchase_status']" icon="pi pi-check" :label="String(purchase_units_totals(slotProps.data['Item no.'])) + ' units (' + String(purchase_totals(slotProps.data['Item no.'])) + ' packs) - '+ format_currency(purchase_cost_totals(slotProps.data['Item no.']))" class="bg-green-600 text-white mr-2" />
                    </template>
                </Column>
            </DataTable>
        </div>
    <!----------------------------------------------->
    <!---------------- Edit Campaign ----------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="edit_campaign_dialog" :style="{width: '800px'}" header="Edit Campaign" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="campaign.name" class="w-full" />
                    <label for="name">Campaign name</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber mode="currency" currency="CHF" locale="de-CH" id="budget" v-model="campaign.purchase_budget" class="w-full"  />
                    <label for="budget">Purchase budget</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <Button label="Store budgets" @click="show_store_budgets = true" icon="pi pi-plus" class="w-auto" />
            </div>
            <div class="field col-12 mt-3" v-if="show_store_budgets">
                <div v-for="store in locations" :key="store.id" class="mt-4">
                    <span class="p-float-label">
                        <InputNumber mode="currency" currency="CHF" locale="de-CH" id="store_budget" v-model="campaign['store_budgets'][store.id]" class="w-full"  />
                        <label for="store_budget">{{store.id}} ({{ store.name }})</label>
                    </span>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <Button icon="pi pi-save" :disabled="campaign.name ? false : true" label="Save changes" @click="save_campaign(), edit_campaign_dialog = false" class="w-auto" />
            </div>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!-------------- Campaign Report ---------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="view_campaign_report" :style="{width: '100%'}" header="Edit Campaign" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_campaign_report['global'].value" placeholder="Suche" style="width: 300px" />
                    </span>
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable v-model:filters="filter_campaign_report" :value="campaign_report_data.items" :rows="100" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[100,400,800]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="Campaign" header="Campaign" sortable>
                    <template #body="slotProps">
                        <span >{{slotProps.data.Campaign}}</span>
                    </template>
                </Column>
                <Column field="Item no." header="Item no." sortable>
                    <template #body="slotProps">
                        <span class="font-bold mr-3">{{slotProps.data["Item no."]}}</span>
                    </template>
                </Column>
                <Column field="Item name" header="Item name" sortable>
                    <template #body="slotProps">
                        <span class="mr-2">{{slotProps.data["Item name"]}}</span>
                        <span>({{format_currency(slotProps.data["price_unit"])}})</span>
                    </template>
                </Column>
                <Column field="Main category" header="Main category" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data["Main category"]}}</span>
                    </template>
                </Column>
                <Column field="Sub category" header="Sub category" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data["Sub category"]}}</span>
                    </template>
                </Column>
                <Column field="Display type" header="Display type" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data["Display type"]}}</span>
                    </template>
                </Column>
                <Column field="total_profit2" header="Profit" sortable>
                    <template #body="slotProps">
                        <span>{{format_currency(slotProps.data["total_profit2"])}}</span>
                    </template>
                </Column>
                <Column field="total_purchase_quantity" header="Purchased" sortable>
                    <template #body="slotProps">
                        <span>{{format_number(slotProps.data["total_purchase_quantity"])}}</span>
                    </template>
                </Column>
                <Column field="total_sales_quantity" header="Sold" sortable>
                    <template #body="slotProps">
                        <span>{{format_number(slotProps.data["total_sales_quantity"])}}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!---------------- New Campaign ----------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="new_campaign_dialog" :style="{width: '800px'}" header="New Campaign" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <div class="flex align-content-center flex-wrap">
                    <input type="file" @change="onSelectFile" style="display: none" ref="fileUpload" accept="application/vnd.*" />
                    <Button :label="selected_xls_file_name ? selected_xls_file_name.name : 'Upload XLSX File'" @click="fileUpload.click()" class="w-auto mr-4 mb-1" icon="pi pi-upload" />
                </div>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="new_campaign.name || new_campaign.runtime || selected_xls_file_name ? false : true" label="Create new campaign" @click="create_new_campaign" class="w-auto" />
            </div>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!--------------- Purchase Slider --------------->
    <!----------------------------------------------->
    <Sidebar v-model:visible="purchase_slider" position="left" style="width: 90%">
        <div class="grid">
            <div class="col-8">
                <div class="mt-2">
                    <span class="font-bold mr-2 text-lg">{{ items_todo_purchase[item_index_purchase]['Item no.'] }}:</span>
                    <span class="text-lg">{{ items_todo_purchase[item_index_purchase]['Item name'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Package size:</span>
                    <span class="mr-3">{{ items_todo_purchase[item_index_purchase]['Package size'] }}</span>
                    <span class="font-bold mr-2">Campaign:</span>
                    <span class="mr-3">{{ items_todo_purchase[item_index_purchase]['Campaign'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Rec. sale price:</span>
                    <span class="mr-3">{{ format_currency(items_todo_purchase[item_index_purchase]['Recommended sales price (LCY)']) }}</span>
                    <span class="font-bold mr-2">Cost price:</span>
                    <span class="mr-3">{{ format_currency(items_todo_purchase[item_index_purchase]['Zebra COGS (LCY)']) }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Categories:</span>
                    <span class="mr-2">{{ items_todo_purchase[item_index_purchase]['Main category'] }}</span>
                    <span class="mr-2">-</span>
                    <span class="mr-3">{{ items_todo_purchase[item_index_purchase]['Sub category'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Display type:</span>
                    <span class="mr-3">{{ items_todo_purchase[item_index_purchase]['Display type'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Commercial theme:</span>
                    <span class="mr-3">{{ items_todo_purchase[item_index_purchase]['Commercial theme'] }}</span>
                </div>
            </div>
            <div class="col-4">
                <div class="flex justify-content-center flex-wrap">
                    <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + items_todo_purchase[item_index_purchase]['Item no.'] + '.jpg'" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + items_todo_purchase[item_index_purchase]['Item no.'] + '.jpg\' />', escape: false}" class="w-8rem w-8rem shadow-2 mb-4" />
                </div>
                <div class="flex justify-content-center flex-wrap">
                    <Button icon="pi pi-caret-left" rounded @click="item_index_purchase--" class="mr-2" :disabled="item_index_purchase == 0 ? true : false" />
                    <Chip :label="String(item_index_purchase + 1) + ' / ' + String(items_todo_purchase.length)" class="mr-2 mb-1" />
                    <Button icon="pi pi-caret-right" rounded @click="item_index_purchase++" />
                </div>
            </div>
            <div class="col-12">
                <div class="grid">
                    <div class="col-3">
                        <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                            <div class="font-bold w-full mt-2">Total budget</div>
                            <div class="w-full mt-2"><ProgressBar :value="100" v-tooltip.top="{value: 'Status total purchase budget', escape: false}">0%</ProgressBar></div>
                            <div class="w-full mt-2">CHF 0.00 / CHF 0.00</div>
                            <div class="w-full mt-2"><Button class="w-full" label="Store budgets" icon="pi pi-building" @click="view_store_budgets_dialog = true" /></div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                            <div class="font-bold w-full mt-2">References</div>
                            <template v-for="(metric, index) in product_purchase_metrics" :key="metric">
                                <div class="font-bold w-full mt-2 pb-2 border-bottom-1 border-blue-100">
                                    <Chip class=" bg-primary mr-2 cursor-pointer" :label="String(metric.id)" @click="show_product_infos({'data': {'item_no': metric.id}})" />
                                    <span class="mr-2">Select Campaign:</span>
                                    <Chip v-for="campaign in metric['campaign_ids']" @click="apply_purchase_recommendations(String(campaign), index, metric.id, items_todo_purchase[item_index_purchase]['Item no.'])" :key="campaign" :label="String(campaign)" class="mr-2 text-sm cursor-pointer text-white hover:bg-primary hover:text-black-alpha-90" :class="selected_match_campaign == String(campaign) ? 'bg-green-600' : 'bg-black-alpha-90'" icon="pi pi-megaphone" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-2">
                        <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                            <div class="w-full mt-2">
                                <Button @click="change_purchase_status(items_todo_purchase[item_index_purchase]['Item no.'], true)" :disabled="count_flags(items_todo_purchase[item_index_purchase]['Item no.']) > 0 ? true : false" label="Done" icon="pi pi-check" showButtons class="neuraxis_inputnumber w-auto bg-green-600 text-white border-green-700" :badge="String(count_flags(items_todo_purchase[item_index_purchase]['Item no.']))" badgeSeverity="danger" />
                            </div>
                            <div class="w-full mt-2">
                                <span v-if="count_flags(items_todo_purchase[item_index_purchase]['Item no.']) > 0 " class="text-sm text-red-600">{{ count_flags(items_todo_purchase[item_index_purchase]['Item no.']) }} locations are flagged for completion</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="grid z-5 bg-white" style="position: sticky; top: 0" v-if="selected_match_campaign">
                            <div class="col-2">
                                <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                    <div class="w-full mt-2 text-sm">Campaign:</div>
                                    <div class="font-bold w-full mt-2 text-2xl flex justify-content-center">{{ selected_match_campaign }}</div>
                                    <div class="w-full mt-2 text-sm"><strong>Start:</strong> {{ format_swiss_date(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].campaign_start_date) }}</div>
                                    <div class="w-full text-sm"><strong>End:</strong> {{ format_swiss_date(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].campaign_end_date) }}</div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                    <div class="w-full mt-2 text-sm">Locations / Soldout:</div>
                                    <div class="font-bold w-full mt-2 text-3xl flex justify-content-center">
                                        {{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].sold_in_locations }} / <span class="text-green-500 ml-2">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].sold_out_locations }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                    <div class="w-full mt-2 text-sm">Purchased / Sold:</div>
                                    <div class="font-bold w-full mt-2 text-3xl flex justify-content-center">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].total_purchase }} / <span class="text-green-500 ml-2">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].total_quantity_sold }}</span></div>
                                    <div class="w-full mt-2 text-sm"><strong>Percent sold:</strong> {{ Percentage(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].total_quantity_sold, product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].total_purchase) }}%</div>
                                    <div class="w-full text-sm"><strong>Left over:</strong> {{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].total_purchase - product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].total_quantity_sold }}</div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                    <div class="w-full mt-2 text-sm">Average sale/day:</div>
                                    <div class="font-bold w-full mt-2 text-2xl flex justify-content-center">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].average_sales_per_day }}</div>
                                    <div class="w-full mt-2 text-sm"><strong>Average days:</strong> {{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].average_days_in_sale }}</div>
                                    <div class="w-full text-sm"><strong>Average days left:</strong> {{ 90 - product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].average_days_in_sale }} ({{ Percentage(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].average_days_in_sale, 90) }}%)</div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                    <div class="w-full mt-2 text-sm">Purchasing:</div>
                                    <div class="font-bold w-full mt-2 text-3xl flex justify-content-center">{{ purchase_totals(items_todo_purchase[item_index_purchase]['Item no.']) }} <span class="font-normal ml-2">packs</span></div>
                                    <div class="w-full mt-2 text-sm"><strong>Total cost price:</strong> {{ format_currency(purchase_cost_totals(items_todo_purchase[item_index_purchase]['Item no.'])) }}</div>
                                    <div class="w-full text-sm"><strong>Total sales price:</strong> {{ format_currency(purchase_sale_totals(items_todo_purchase[item_index_purchase]['Item no.'])) }}</div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                    <div class="w-full mt-2">
                                        <Button label="Overwrite amounts" icon="pi pi-calculator" class="w-auto bg-orange-600 text-white border-orange-700" @click="overwrite_purchase_amounts()" />
                                    </div>
                                    <div class="w-full mt-2">
                                        <Button label="Save changes" icon="pi pi-save" class="w-auto bg-green-600 text-white green-orange-700" @click="save_campaign()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-3">
                <div class="col-12">
                    <div class="grid">
                        <template v-for="(location) in locations" :key="location">
                            <div class="col-3 border-bottom-1 border-blue-200 p-2 pt-5" :class="campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['flag'] ? 'bg-red-100' : ''">
                                <div class="w-full"><span class="font-bold mr-2">{{ location.id }}</span><span>{{ location.name }}</span></div>
                                <div class="w-full mt-2"><Button icon="pi pi-chart-line" :label="String(selected_match_campaign_item) + ' statistics'" @click="show_product_infos({'data': {'item_no': selected_match_campaign_item}}, location.id, selected_match_campaign)" class="w-auto" /></div>
                            </div>
                            <div class="col-6 border-bottom-1 border-blue-200 p-2 pt-5" :class="campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['flag'] ? 'bg-red-100' : ''">
                                <template v-if="selected_match_campaign">
                                    <div v-if="location.id in product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations && product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].total_quantity_sold > 0" class="grid">
                                        <div class="col-3">
                                            <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                                <div class="w-full mt-2 text-sm">Sold out</div>
                                                <div v-if="product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].sold_out" class="w-full mt-2 font-bold text-green-500 text-xl">Yes</div>
                                                <div v-if="!product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].sold_out" class="w-full mt-2 font-bold text-red-500 text-xl">No</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                                <div class="w-full mt-2 text-sm">Runtime</div>
                                                <div class="w-full mt-2 font-bold">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].days_in_sale }} ({{ Percentage(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].days_in_sale, 90) }})%</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                                <div class="w-full mt-2 text-sm">Purch. / Sale</div>
                                                <div class="w-full mt-2 font-bold">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].total_purchase }} / <span class="text-green-500">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].total_quantity_sold }}</span></div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div rounded class="overflow-hidden shadow-3 border-200 p-2 w-full h-full">
                                                <div class="w-full mt-2 text-sm">Avg. sales/day</div>
                                                <div class="w-full mt-2 font-bold text-xl">{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].average_sales_per_day }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="col-3 border-bottom-1 border-blue-200 p-2 pt-5" :class="campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['flag'] ? 'bg-red-100' : ''">
                                <div class="grid">
                                    <div class="col-9">
                                        <div v-if="selected_match_campaign" class="text-sm">Rec. <strong>{{ product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].recommended_purchase }}</strong> units (<strong>{{ Math.round(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].recommended_purchase / items_todo_purchase[item_index_purchase]['Package size']) }}</strong> packs)<i class="pi pi-plus cursor-pointer text-green-500 hover:text-primary text-sm ml-2" v-tooltip.top="'Apply this recommendation'" @click="campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['total'] = Math.round(product_purchase_metrics[selected_match_campaign_index].campaigns[selected_match_campaign].locations[location.id].recommended_purchase / items_todo_purchase[item_index_purchase]['Package size'])" /></div>
                                        <InputNumber id="Packages" suffix=" packs" class="w-full neuraxis_inputnumber" showButtons v-model="campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['total']" />
                                    </div>
                                    <div class="col-3 flex align-items-center justify-content-center">
                                        <Checkbox :binary="true" v-tooltip.top="'Flag this for later'" v-model="campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['flag']" />
                                    </div>
                                    <div class="col-12 flex align-items-center p-campaign.purchase[selected_match_campaign_item]0 pl-3">
                                        <small>{{ campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['total'] }} x {{items_todo_purchase[item_index_purchase]['Package size']}} x {{ format_currency_without_prefix(parseFloat(items_todo_purchase[item_index_purchase]['Zebra COGS (LCY)'])) }} = <span class="font-bold">{{ format_currency((campaign['purchase'][items_todo_purchase[item_index_purchase]['Item no.']][location.id]['total'] * (parseFloat(items_todo_purchase[item_index_purchase]['Package size'])) * parseFloat(items_todo_purchase[item_index_purchase]['Zebra COGS (LCY)']))) }}</span></small>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
    <!----------------------------------------------->
    <!------------- Store budget dialog ------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="view_store_budgets_dialog" :style="{width: '60%'}" header="Store budgets" :modal="true" class="p-fluid z-5">
        <div class="grid mt-2">
            <template v-for="store in locations" :key="store.id">
                <div class="col-1">
                    <span class="font-bold" v-tooltip.top="store.name">{{ store.id }}</span>
                </div>
                <div class="col-4">
                    <span>{{ store.name }}</span>
                </div>
                <div class="col-4">
                    <ProgressBar :value="100" v-tooltip.top="{value: 'Status budget for store: ' + String(store.id) + ' - ' + store.name, escape: false}">0%</ProgressBar>
                </div>
                <div class="col-3">
                    {{ format_currency(store_purchase_total(store.id)) }} / {{ format_currency(campaign['store_budgets'][store.id]) }}
                </div>
            </template>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!---------- References search Slider ----------->
    <!----------------------------------------------->
    <Sidebar v-model:visible="references_search_slider" position="left" style="width: 70%">
        <div class="grid">
            <div class="col-8">
                <div class="mt-2">
                    <span class="font-bold mr-2 text-lg">{{ items_todo[item_index]['Item no.'] }}:</span>
                    <span class="text-lg">{{ items_todo[item_index]['Item name'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Package size:</span>
                    <span class="mr-3">{{ items_todo[item_index]['Package size'] }}</span>
                    <span class="font-bold mr-2">Campaign:</span>
                    <span class="mr-3">{{ items_todo[item_index]['Campaign'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Rec. sale price:</span>
                    <span class="mr-3">{{ format_currency(items_todo[item_index]['Recommended sales price (LCY)']) }}</span>
                    <span class="font-bold mr-2">Cost price:</span>
                    <span class="mr-3">{{ format_currency(items_todo[item_index]['Zebra COGS (LCY)']) }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Categories:</span>
                    <span class="mr-2">{{ items_todo[item_index]['Main category'] }}</span>
                    <span class="mr-2">-</span>
                    <span class="mr-3">{{ items_todo[item_index]['Sub category'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Display type:</span>
                    <span class="mr-3">{{ items_todo[item_index]['Display type'] }}</span>
                </div>
                <div>
                    <span class="font-bold mr-2">Commercial theme:</span>
                    <span class="mr-3">{{ items_todo[item_index]['Commercial theme'] }}</span>
                </div>
            </div>
            <div class="col-4">
                <div class="flex justify-content-center flex-wrap">
                    <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + items_todo[item_index]['Item no.'] + '.jpg'" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + items_todo[item_index]['Item no.'] + '.jpg\' />', escape: false}" class="w-8rem w-8rem shadow-2 mb-4" />
                </div>
                <div class="flex justify-content-center flex-wrap">
                    <Button icon="pi pi-caret-left" rounded @click="item_index--" class="mr-2" :disabled="item_index == 0 ? true : false" />
                    <Chip :label="String(item_index + 1) + ' / ' + String(items_todo.length)" class="mr-2 mb-1" />
                    <Button icon="pi pi-caret-right" rounded @click="item_index++" />
                </div>
            </div>
            <div class="col-8">
                <div>
                    <span class="font-bold mr-2">Reference items</span>
                    <Badge v-if="items_todo[item_index]['matches'].length > 0" :value="String(items_todo[item_index]['matches'].length)" class="bg-green-600 text-white" />
                    <Badge v-if="items_todo[item_index]['matches'].length == 0" :value="String(items_todo[item_index]['matches'].length)" class="bg-red-600 text-white" />
                </div>
                <div class="p-2">
                    <Chip v-for="item in items_todo[item_index]['matches']" :key="item" class="mr-2 mb-1 p-2">
                        <span class="font-bold ml-2 mr-2">{{ item }}</span>
                        <i class="pi pi-times-circle cursor-pointer mr-2" @click="remove_reference(items_todo[item_index]['Item no.'], item)" />
                    </Chip>
                </div>
            </div>
            <div class="col-4">
                <div class="flex align-items-center justify-content-center">
                    <Button label="Done" icon="pi pi-check" @click="change_reference_status(items_todo[item_index]['Item no.'], true), search_reference(items_with_status_not_done())" class="mr-2 hover:bg-green-600 hover:text-white bg-gray-200 text-black-alpha-90 border-gray-300 hover:border-green-700" />
                </div>
            </div>
            <div class="col-12">
                <TabView class="tabview-custom">
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="font-bold white-space-nowrap mr-1">Similar name</span>
                                <Badge :value="String(similar_items.length)" />
                            </div>
                        </template>
                        <Toolbar>
                            <template #start>
                                <span class="p-input-icon-left mr-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="search_term" placeholder="Suche" style="width: 300px" />
                                </span>
                                <Button :disabled="!search_term ? true : false" label="Search" icon="pi pi-search" @click="search_reference_manual(search_term)" class="mr-2" />
                            </template>
                        </Toolbar>
                        <div rounded class="overflow-hidden shadow-3 border-200">
                            <div class="flex justify-content-center flex-wrap">
                                <ProgressSpinner v-if="search_loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
                            </div>
                            <DataTable v-if="!search_loader" :value="similar_items" :rows="10" responsiveLayout="scroll" :rowHover="true" class="rounded">
                                <Column field="id" header="Item no.">
                                    <template #body="slotProps">
                                        <span class="font-bold cursor-pointer" @click="show_product_infos(slotProps)">{{ slotProps.data["id"] }}</span>
                                    </template>
                                </Column>
                                <Column field="description" header="Description">
                                    <template #body="slotProps">
                                        <span class="cursor-pointer" @click="show_product_infos(slotProps)">{{ slotProps.data["description"] }}</span>
                                    </template>
                                </Column>
                                <Column field="campaigns" header="Campaigns">
                                    <template #body="slotProps">
                                        <Chip v-for="campaign in slotProps.data['campaigns']" :key="campaign" :label="String(campaign)" class="mr-2 bg-black-alpha-90 text-white mb-1" />
                                    </template>
                                </Column>
                                <Column field="price_unit" header="Unit">
                                    <template #body="slotProps">
                                        <span>{{ format_currency_without_prefix(slotProps.data["price_unit"]) }}</span>
                                    </template>
                                </Column>
                                <Column field="cost_unit" header="Cost">
                                    <template #body="slotProps">
                                        <span>{{ format_currency_without_prefix(slotProps.data["cost_unit"]) }}</span>
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-plus" @click="add_reference(items_todo[item_index]['Item no.'], slotProps.data['id'])" rounded />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="font-bold white-space-nowrap mr-1">Campaign explorer</span>
                                <Badge :value="String(campaign_search_data.performance.length)" />
                            </div>
                        </template>
                        <Toolbar>
                            <template #start>
                                <span class="p-input-icon-left mr-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filter_campaigns_search['global'].value" placeholder="Suche" style="width: 300px" />
                                </span>
                                <Dropdown v-model="selected_campaign_search" optionLabel="name" optionValue="id" :options="campaigns" placeholder="Select a campaign" class="mr-2" @change="select_campaign_search" style="width: 300px" />
                            </template>
                        </Toolbar>
                        <div rounded class="overflow-hidden shadow-3 border-200">
                            <DataTable v-model:filters="filter_campaigns_search" :value="campaign_search_data['performance']" :rows="10" responsiveLayout="scroll" :rowHover="true" class="rounded">
                                <Column field="item_no" header="Item no.">
                                    <template #body="slotProps">
                                        <span class="font-bold cursor-pointer" @click="show_product_infos(slotProps)">{{ slotProps.data["item_no"] }}</span>
                                    </template>
                                </Column>
                                <Column field="Item name" header="Description">
                                    <template #body="slotProps">
                                        <span class="cursor-pointer" @click="show_product_infos(slotProps)">{{ slotProps.data["Item name"] }}</span>
                                    </template>
                                </Column>
                                <Column field="Campaign" header="Campaign">
                                    <template #body="slotProps">
                                        <Chip :label="String(slotProps.data.Campaign)" class="mr-2 bg-black-alpha-90 text-white" />
                                    </template>
                                </Column>
                                <Column field="Main category" hidden />
                                <Column field="Sub category" hidden />
                                <Column field="Display type" hidden />
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-plus" @click="add_reference(items_todo[item_index]['Item no.'], slotProps.data['item_no'])" rounded />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </Sidebar>
    <!----------------------------------------------->
    <!------------ Product Infos Slider ------------->
    <!----------------------------------------------->
    <Sidebar v-model:visible="product_infos_slider" position="right" style="width: 50%">
        <ProductMetrics :product="product_sku" :location="selected_location_prop" :campaign="selected_campaign_prop" />
    </Sidebar>
    <!----------------------------------------------->
    <!-------- Product Purchase Infos Slider -------->
    <!----------------------------------------------->
    <Sidebar v-model:visible="product_purchase_infos_slider" position="left" style="width: 70%">
        <div class="grid">
            <div class="col-10">
                <div class="text-2xl font-bold mr-2 flex align-items-center">
                    {{ product_summary_infos["Item no."] }}
                    <i class="pi pi-copy cursor-pointer ml-2 text-primary" @click="copy_to_clipboard(product_summary_infos['Item no.'])" v-tooltip.top="'Copy to clipboard'" />
                </div>
                <div class="text-2xl">{{ product_summary_infos["Item name"] }}</div>
            </div>
            <div class="col-2">
                <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + product_summary_infos['Item no.'] + '.jpg'" class="w-5rem h-5rem shadow-2" v-tooltip.left="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + product_summary_infos['Item no.'] + '.jpg\' />', escape: false}" />
            </div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Package size</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["Package size"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Zebra COGS</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ format_currency(product_summary_infos["Zebra COGS (LCY)"]) }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Main category</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["Main category"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Sub category</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["Sub category"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Product type</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["Product type"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Display type</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["Display type"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">VmLocation</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["VmLocation"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">VmModule</div>
            <div class="col-4 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["VmModule"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Comm. theme</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1">{{ product_summary_infos["Commercial theme"] }}</div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50 flex align-items-center">References</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1 flex align-items-center">
                <Chip v-for="item in product_summary_infos['matches']" :key="item" class="mr-2 mb-1 p-2">
                    <span class="ml-2 mr-2 font-bold cursor-pointer" @click="show_product_infos({'data': {'item_no': item}})">{{item}}</span>
                    <i class="pi pi-times-circle cursor-pointer mr-2" @click="remove_reference(product_summary_infos['Item no.'], item)" />
                </Chip>
            </div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Reference Status</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1 flex align-items-center">
                <InputSwitch v-model="product_summary_infos['reference_status']" class="mr-2" />
                <span v-if="!product_summary_infos['reference_status']" class="font-bold text-red-600">Not done</span>
                <span v-if="product_summary_infos['reference_status']" class="font-bold text-green-600">Done</span>
                <span v-if="!product_summary_infos['reference_status']" class="cursor-pointer ml-4 text-primary font-medium" @click="search_reference_manual_item(product_summary_infos['Item no.'])">Search refrences for: {{ product_summary_infos['Item no.'] }}</span>
                <span v-if="!product_summary_infos['reference_status']" class="cursor-pointer ml-4 text-green-600 font-medium" @click="product_summary_infos['reference_status'] = true, save_campaign()">Set Done</span>
                <span v-if="product_summary_infos['reference_status']" class="cursor-pointer ml-4 text-red-600 font-medium" @click="product_summary_infos['reference_status'] = false, save_campaign()">Set to not Done</span>
            </div>
            <div class="col-2 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">Purchase Status</div>
            <div class="col-10 text-sm border-blue-100 border-solid border-bottom-1 flex align-items-center">
                <InputSwitch v-model="product_summary_infos['purchase_status']"  class="mr-2" @change="change_purchase_status(product_summary_infos['Item no.'], product_summary_infos['purchase_status'])" />
                <span v-if="!product_summary_infos['purchase_status']" class="font-bold text-red-600">Not done</span>
                <span v-if="product_summary_infos['purchase_status']" class="font-bold text-green-600">Done</span>
                <span v-if="!product_summary_infos['purchase_status'] && product_summary_infos['reference_status']" class="cursor-pointer ml-4 text-primary font-medium" @click="search_purchase_manual_item(product_summary_infos['Item no.'])">Search purchase for: {{ product_summary_infos['Item no.'] }}</span>
                <span v-if="!product_summary_infos['purchase_status'] && product_summary_infos['reference_status']" class="cursor-pointer ml-4 text-green-600 font-medium" @click="product_summary_infos['purchase_status'] = true, save_campaign()">Set Done</span>
                <span v-if="product_summary_infos['purchase_status'] && product_summary_infos['reference_status']" class="cursor-pointer ml-4 text-red-600 font-medium" @click="product_summary_infos['purchase_status'] = false, save_campaign()">Set to not Done</span>
            </div>
            <template v-if="product_summary_infos['purchase_status'] && campaign['purchase'][product_summary_infos['Item no.']]">
                <div class="col-4 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">Purchase locations</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">Units</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">Packs</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">Cost</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">Sales price</div>
                <div class="col-4 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">Totals</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">{{ purchase_units_totals(product_summary_infos["Item no."]) }}</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">{{ purchase_totals(product_summary_infos["Item no."]) }}</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">{{ format_currency(purchase_cost_totals(product_summary_infos["Item no."]))}}</div>
                <div class="col-2 text-sm font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50">{{ format_currency(purchase_sale_totals(product_summary_infos["Item no."]))}}</div>
                <template v-for="(location, index) in locations" :key="index">
                    <div class="col-4 text-sm font-medium border-blue-100 border-solid border-bottom-1 bg-blue-50">{{ location.name }}</div>
                    <div class="col-2 text-sm border-blue-100 border-solid border-bottom-1 border-left-1">{{ campaign['purchase'][product_summary_infos['Item no.']][location.id]['total_units'] }}</div>
                    <div class="col-2 text-sm border-blue-100 border-solid border-bottom-1 border-left-1">{{ campaign['purchase'][product_summary_infos['Item no.']][location.id]['total'] }}</div>
                    <div class="col-2 text-sm border-blue-100 border-solid border-bottom-1 border-left-1">{{ format_currency(campaign['purchase'][product_summary_infos['Item no.']][location.id]['total_cost']) }}</div>
                    <div class="col-2 text-sm border-blue-100 border-solid border-bottom-1 border-left-1">{{ format_currency(campaign['purchase'][product_summary_infos['Item no.']][location.id]['total_price']) }}</div>
                </template>
            </template>
        </div>
    </Sidebar>
    <!----------------------------------------------->
    <!-------- Price Calculator Sidebar ------------>
    <!----------------------------------------------->
    <Sidebar v-model:visible="price_calculator_slider" position="right" style="width: 70%">
        <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab header="Revenue calculator">
                <div class="col-12 formgrid grid">
                    <DataTable :value="price_calculator_items" :rows="100" responsiveLayout="scroll" :rowHover="true" class="rounded w-full">
                        <Column field="Item no." header="Item no." sortable>
                            <template #body="slotProps">
                                <span class="font-bold">{{slotProps.data["Item no."]}}</span>
                            </template>
                        </Column>
                        <Column field="Item name" header="Item name" sortable>
                            <template #body="slotProps">
                                <span class="mr-2">{{slotProps.data["Item name"]}}</span>
                            </template>
                        </Column>
                        <Column field="price_unit" header="Price" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency(slotProps.data.price_unit)}}</span>
                            </template>
                        </Column>
                        <Column field="cost_unit" header="Cost" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency(slotProps.data.cost_unit)}}</span>
                            </template>
                        </Column>
                        <Column field="quantity" header="Quantity" sortable>
                            <template #body="slotProps">
                                <span>{{format_number(slotProps.data.total_purchase_quantity - slotProps.data.total_sales_quantity)}}</span>
                            </template>
                        </Column>
                        <Column field="profit" header="Profit" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency((slotProps.data.price_unit - slotProps.data.cost_unit) * (slotProps.data.total_purchase_quantity - slotProps.data.total_sales_quantity))}} ({{ Math.round((100 * (1 - (slotProps.data.cost_unit / slotProps.data.price_unit))), 2)}}%)</span>
                            </template>
                        </Column>
                        <Column field="new_price" header="New price" sortable>
                            <template #body="slotProps">
                                <InputNumber v-model="slotProps.data.new_price" locale="ch-DE" :minFractionDigits="2" />
                            </template>
                        </Column>
                        <Column field="profit_after" header="Profit after" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency((slotProps.data.new_price - slotProps.data.cost_unit) * (slotProps.data.total_purchase_quantity - slotProps.data.total_sales_quantity))}} ({{ Math.round(100*(1 - (slotProps.data.cost_unit / slotProps.data.new_price)), 2)}}%)</span>
                            </template>
                        </Column>
                    </DataTable>
                    <div class="field col-12 mt-3">
                        <ProgressBar :value="calculate_totals_percent(price_calculator_items)" v-tooltip.top="{value: 'Left over after campaign end', escape: false}">Profit: {{format_currency(calculate_totals(price_calculator_items))}} ({{calculate_totals_percent(price_calculator_items)}}%)</ProgressBar>
                    </div>
                    <div class="field col-12 mt-3">
                        <Button label="Launch price change workflow" icon="pi pi-sitemap" class="w-auto" />
                    </div>
                </div> 
            </AccordionTab>
        </Accordion>
    </Sidebar>
    </BlockUI>
</template>