<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const transaction_nodes = ref()
const campaign_nodes = ref()
const product_nodes = ref()

onMounted(() => {
    get_transaction_nodes()
    get_campaign_nodes()
})

const get_transaction_nodes = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/get-transaction-sync-status")
        .then(response => {
            console.log(response.data)
            transaction_nodes.value = response.data
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const get_campaign_nodes = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/get-campaign-sync-status")
        .then(response => {
            console.log(response.data)
            campaign_nodes.value = response.data
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}


const open_api_url = (url) => {
    window.open(process.env.VUE_APP_NEURAXIS_API_MAIN + url, '_blank')
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="grid">
            <TabView class="col-12" ref="tabview4">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-database mr-2"></i>
                        <span class="mr-3">Transactions</span>
                    </template>
                    <div class="grid">
                        <div class="col-12">
                            <Tree :value="transaction_nodes" class="w-full">
                                <template #default="slotProps">
                                    <span class="mr-2">{{ slotProps.node.label }}</span>
                                    <Chip :label="String(slotProps.node.children.length)" />
                                </template>
                                <template #day="slotProps">
                                    <span class="mr-4">{{ slotProps.node.label }}</span>
                                    <i class="pi pi-download cursor-pointer" @click="open_api_url('/flyingtigerv2/download-campaign-json?key='+slotProps.node.data)"></i>
                                </template>
                            </Tree>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-database mr-2"></i>
                        <span class="mr-3">Campaigns</span>
                    </template>
                    <div class="grid">
                        <div class="col-12">
                            <Tree :value="campaign_nodes" class="w-full">
                                <template #default="slotProps">
                                    <span class="mr-2">{{ slotProps.node.label }}</span>
                                    <Chip :label="String(slotProps.node.children.length)" />
                                </template>
                                <template #import="slotProps">
                                    <span class="mr-4">{{ slotProps.node.label }}</span>
                                    <i class="pi pi-trash cursor-pointer text-red-600" @click="console.log('delete campaign import: ' + slotProps.node.data)"></i>
                                </template>
                                <template #performance="slotProps">
                                    <span class="mr-4">{{ slotProps.node.label }}</span>
                                    <i class="pi pi-sync cursor-pointer text-blue-600 mr-2" @click="console.log('sync campaign performance: ' + slotProps.node.data)"></i>
                                    <i class="pi pi-trash cursor-pointer text-red-600" @click="console.log('delete campaign performance: ' + slotProps.node.data)"></i>
                                </template>
                                <template #transactions="slotProps">
                                    <span class="mr-4">{{ slotProps.node.label }}</span>
                                    <i class="pi pi-sync cursor-pointer text-blue-600 mr-2" @click="console.log('sync campaign transactions: ' + slotProps.node.data)"></i>
                                    <i class="pi pi-trash cursor-pointer text-red-600" @click="console.log('delete campaign transactions: ' + slotProps.node.data)"></i>
                                </template>
                                <template #campaign_source="slotProps">
                                    <span class="mr-4">{{ slotProps.node.label }}</span>
                                    <i class="pi pi-trash cursor-pointer text-red-600" @click="console.log('delete campaign transactions: ' + slotProps.node.data)"></i>
                                </template>
                            </Tree>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    </BlockUI>
</template>