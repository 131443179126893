<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const props = defineProps({
  workflow_id: { type: String }
})

const filter_purchase = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_campaign_report = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const expandedRows = ref([])

const purchase = ref({})
const stores = ref([])
const campaigns = ref([])
const selected_campaign = ref()
const total_products_without_references = ref(0)

onMounted( async () => {
    get_purchase()
})

const get_purchase = () => {
    loader.value = true
    let payload = {
        "id": props.workflow_id
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-purchase', payload)
    .then(response => {
        console.log("purchase", response.data)
        purchase.value = response.data
        total_products_without_references.value = count_products_without_references()
        loader.value = false
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-campaigns')
    .then(response => {
        campaigns.value = response.data
    })
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_number = (value) => {
    return Number(value).toLocaleString('de-CH');
}

const save_purchase = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/save-purchase', purchase.value)
    .then(response => {
        if(response.data.status == "success") {
            get_purchase()
            toast.add({severity:'success', summary: 'Success', detail: 'Purchase was successfully saved', life: 3000})
            loader.value = false
        } else {
            toast.add({severity:'error', summary: 'Error', detail: 'Purchase could not be saved!', life: 3000})
            loader.value = false
        }
    })
}

const count_products_without_references = () => {
    let count = 0
    purchase.value.items.forEach((item, index) => {
        if (item["matches"].length == 0) {
            count++
        }
    })
    return count
}

const product_details = (e) => {
    loader.value = true
    console.log(e.data)
    details.value = e.data
    details_index.value = e.index
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-product', {id: e.data.No_})
    .then(response => {
        product.value = response.data
        ledger.value["datasets"] = product.value["volume"]
        stock_ledger.value = product.value["stock_total"]
        console.log(product.value)
        loader.value = false
        productDetailsDialog.value = true
        chartOptions.value = setChartOptions();
    })
}
const references = ref([])
const current_purchase_item_no = ref()
const current_purchase_item_name = ref()
const current_purchase_item_recommended_price = ref()
const calculate_product = (purchase_item, refs) => {
    loader.value = true
    current_purchase_item_no.value = purchase_item["Item no."]
    current_purchase_item_name.value = purchase_item["Item name"]
    current_purchase_item_recommended_price.value = purchase_item["Recommended sales price (LCY)"]
    references.value = []
    for (let i = 0; i < refs.length; i++) {
        references.value.push(refs[i]["No_"])
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-product-calculations', {id: references.value, purchase_item: purchase_item, campaign_duration: campaign_duration.value})
    .then(response => {
        //console.log("calcs", response.data)
        stores.value = response.data
        // get index of purchase item
        purchase_amount.value = {}
        let purchase_index = purchase.value.items.findIndex(item => item["Item no."] == purchase_item["Item no."])
        if (!purchase.value.items[purchase_index]["purchase_amount"]){
            console.log("purchase amount does not exist")
            for (let i = 0; i < stores.value.length; i++) {
                if (!purchase_amount.value[stores.value[i].id]) {
                    if (stores.value[i].recommended_volume[0]) {
                        purchase_amount.value[stores.value[i].id] = Math.ceil(stores.value[i].recommended_volume[0] / stores.value[i].packaging)
                        purchase_amount_validation.value[stores.value[i].id] = false
                    } else {
                        purchase_amount.value[stores.value[i].id] = 0
                        purchase_amount_validation.value[stores.value[i].id] = false
                    }
                }
            }
        } else {
            console.log("purchase amount exists")
            purchase_amount.value = purchase.value.items[purchase_index]["purchase_amount"]
            purchase_amount_validation.value = purchase.value.items[purchase_index]["purchase_amount_validation"]
        }
        console.log("stores.value", stores.value)
        console.log("purchase_amount", purchase_amount.value)
        console.log("purchase_amount_validation", purchase_amount_validation.value)
        purchase_amount_change()
        loader.value = false
        calculatePurchaseDialog.value = true
    })
}

const add_product = (product) => {
    loader.value = true
    //loop purchase.items
    purchase.value.items.forEach((item, index) => {
        if (item["Item no."] == purchase_item_no.value) {
            purchase.value.items[index]["matches"].push(product)
            toast.add({severity:'success', summary: 'Success', detail: product['No_'] + ' was successfully saved as reference product', life: 3000})
            save_purchase()
            loader.value = false
        }
    })
}

const add_campaign_product = (product_id) => {
    loader.value = true
    console.log("product_id", product_id)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-product-only', {id: product_id})
    .then(response => {
        let product = response.data
        console.log("product", product)
        //loop purchase.items
        purchase.value.items.forEach((item, index) => {
            if (item["Item no."] == purchase_item_no.value) {
                purchase.value.items[index]["matches"].push(product)
                toast.add({severity:'success', summary: 'Success', detail: product['No_'] + ' was successfully saved as reference product', life: 3000})
                save_purchase()
                loader.value = false
            }
        })
    })
}

const purchase_item_no = ref()

const product_search = (item_no, item_description) => {
    search_term.value = item_description
    purchase_item_no.value = item_no
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/search-product', {term: search_term.value})
    .then(response => {
        search_results.value = response.data
        searchProductsDialog.value = true
        loader.value = false
    })
}

const manual_search = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/search-product', {term: search_term.value})
    .then(response => {
        search_results.value = response.data
        loader.value = false
    })
}

const product = ref()
const details = ref()
const details_index = ref()
const productDetailsDialog = ref(false)
const ledger = ref({labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novemver', 'December'], datasets: []})
const stock_ledger = ref()
const chartOptions = ref()
const searchProductsDialog = ref(false)
const search_results = ref([])
const search_term = ref()
const calculatePurchaseDialog = ref(false)
const purchase_amount = ref({})
const purchase_amount_validation = ref({})
const campaign_duration = ref(10)

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    }
}

function calculateAverage(array) {
    if (array.length == 0) {
        return 0
    }
    if (Math.max(...array) === 0) {
        return 0
    }
    const filtered = array.filter(item => item !== 0)
    const sum = filtered.reduce((a, b) => a + b)
    if (sum == 0) {
        return 0
    } else {
        const avg = sum / filtered.length
        return Math.round(avg * 100) / 100
    }
}

function calculateOrderAmount(amount, days) {
    return Math.round(amount * days)
}

const total_packages = ref(0)
const total_units = ref(0)
const purchase_amount_change = () => {
    let total = 0
    let units_total = 0
    for (let i = 0; i < Object.keys(purchase_amount.value).length; i++) {
        total += purchase_amount.value[Object.keys(purchase_amount.value)[i]]
        units_total += purchase_amount.value[Object.keys(purchase_amount.value)[i]] * stores.value[i]["packaging"]
    }
    total_packages.value = total
    total_units.value = units_total
}

const purchase_amount_total = (purchase_amount, packaging) => {
    let total = 0
    for (let i = 0; i < Object.keys(purchase_amount).length; i++) {
        total += purchase_amount[Object.keys(purchase_amount)[i]]
    }
    return {"total": total, "units": total * packaging}
}

const purchase_amount_validated = (purchase_amount_validation) => {
    let count = 0
    for (let i = 0; i < Object.keys(purchase_amount_validation).length; i++) {
        if (!purchase_amount_validation[Object.keys(purchase_amount_validation)[i]]) {
            count++
        }
    }
    if (count == 0) {
        return {"status": true}
    } else {
        return {"status": false, "count": count}
    }
}

const check_all_boxes = () => {
    for (let i = 0; i < Object.keys(purchase_amount_validation.value).length; i++) {
        purchase_amount_validation.value[Object.keys(purchase_amount_validation.value)[i]] = true
    }
}

const save_purchase_amount = () => {
    let purchase_index = purchase.value.items.findIndex(item => item["Item no."] == current_purchase_item_no.value)
    purchase.value.items[purchase_index]["purchase_amount"] = purchase_amount.value
    purchase.value.items[purchase_index]["purchase_amount_validation"] = purchase_amount_validation.value
    console.log("purchase.value", purchase.value.items[purchase_index])
    save_purchase()
    toast.add({severity:'success', summary: 'Success', detail: 'Purchase amount was successfully saved', life: 3000})
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

const campaign_report_data = ref({})
const load_campaign = () => {
    console.log("selected_campaign", selected_campaign.value)
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/campaign-report", {id: selected_campaign.value})
        .then(response => {
            campaign_report_data.value = response.data
            console.log("campaign_report", campaign_report_data.value)
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const delete_match = (e) => {
    console.log("delete", e)
    confirm.require({
        message: 'Are you sure you want to delete this item?',
        header: 'Delete item?',
        rejectLabel: 'No',
        acceptLabel: 'Yes, delete it',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            toast.add({severity:'danger', summary: 'Please wait', detail: 'Deleting an item is not yet supported, sorry', life: 3000})
/*
            for (let i = 0; i < purchase.value.items.length; i++) {
                for (let j = 0; j < purchase.value.items[i]["matches"].length; j++) {
                    if (purchase.value.items[i]["matches"][j]["No_"] == e.data["No_"] && j == e.index) {
                        purchase.value.items[i]["matches"].splice(j, 1)
                        save_purchase()
                        toast.add({severity:'success', summary: 'Success', detail: e.data["No_"] + ' was successfully removed as reference product', life: 3000})
                    }
                }
            }
            save_purchase()
            toast.add({severity:'success', summary: 'Success', detail: e.data["No_"] + ' was successfully removed as reference product', life: 3000})
*/
            }

    })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-3">
                <i class="pi pi-search" />
                <InputText v-model="filter_purchase['global'].value" placeholder="Search" class="w-full" />
            </span>
            <span class="mr-3 font-bold">
                Campaign duration (weeks)
            </span>
            <InputNumber v-model="campaign_duration" :min="1" showButtons class="neuraxis_inputnumber mr-3" />
            <span class="mr-3 font-bold">
                Total without references: <span class="text-red-600">{{ total_products_without_references }}</span>
            </span>
        </template>
        <template #end>
            <Button @click="open_url('https://api.snpy.ch/flyingtiger/export-purchase?id=' + props.workflow_id)" label="Export purchase" icon="pi pi-download" class="w-aut mr-2" />
        </template>
    </Toolbar>
    <DataTable v-model:expandedRows="expandedRows" class="mt-3" v-model:filters="filter_purchase" :value="purchase.items" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column expander style="width: 5rem" />
        <Column>
            <template #body="slotProps">
                <div class="flex align-content-center flex-wrap">
                    <Avatar shape="circle" :label="String(slotProps.data['matches'].length)" :class="slotProps.data['matches'].length > 0 ? 'mr-2 bg-green-800 text-white' : 'mr-2 bg-red-800 text-white'" v-tooltip.top="'Reference items'" />
                    <Avatar v-if="slotProps.data['General info']" shape="circle" icon="pi pi-info-circle" class="mr-2 bg-primary text-white" v-tooltip.left="slotProps.data['General info']" />
                </div>
            </template>
        </Column>
        <Column style="width: 100px">
            <template #body="slotProps">
                <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['Item no.'] + '.jpg'" class="w-3rem h-3rem shadow-2" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['Item no.'] + '.jpg\' />', escape: false}" />
            </template>
        </Column>
        <Column field="Item no." header="Item no.">
            <template #body="slotProps">
                <span class="font-bold">{{ slotProps.data["Item no."] }}</span><i @click="copy_to_clipboard(slotProps.data['Item no.'])" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
            </template>
        </Column>
        <Column field="Item name" header="Item name" />
        <Column field="Campaign" header="Campaign" />
        <Column field="Package size" header="Package size" />
        <Column field="Recommended sales price (LCY)" header="Rec. sale price">
            <template #body="slotProps">
                <span>{{ format_currency(slotProps.data["Recommended sales price (LCY)"]) }}</span>
            </template>
        </Column>
        <Column field="order_packages" header="Calculate">
            <template #body="slotProps">
                <Button icon="pi pi-calculator" :disabled="slotProps.data['matches'].length > 0 ? false : true" @click="calculate_product(slotProps.data, slotProps.data['matches'], slotProps.index)" aria-label="Calculate" class="mr-2" v-tooltip.top="slotProps.data.Description + ' Bestellung kalkulation'" />
            </template>
        </Column>
        <Column field="match_ids" header="References" hidden />
        <Column field="purchase_amount" header="Purchase amount">
            <template #body="slotProps">
                <span v-if="slotProps.data['purchase_amount']"><span class="font-bold text-red-800">{{ purchase_amount_total(slotProps.data['purchase_amount'], slotProps.data['Package size'])['total'] }}</span> ({{ purchase_amount_total(slotProps.data['purchase_amount'], slotProps.data['Package size'])['units'] }} Units)</span>
                <span v-else>-</span>
            </template>
        </Column>
        <Column field="purchase_amount_validation" header="Check">
            <template #body="slotProps">
                <span v-if="slotProps.data['purchase_amount_validation']">
                    <Chip v-if="purchase_amount_validated(slotProps.data['purchase_amount_validation'])['status']" class="bg-green-600 text-white" icon="pi pi-check" label="OK" />
                    <Chip v-if="!purchase_amount_validated(slotProps.data['purchase_amount_validation'])['status']" class="bg-red-600 text-white" :label="String(purchase_amount_validated(slotProps.data['purchase_amount_validation'])['count'])" icon="pi pi-times" />
                </span>
            </template>
        </Column>
        <Column field="order_cost" header="Purchase cost" hidden>
            <template #body="slotProps">
                <span class="text-red-500" v-if="slotProps.data.order_packages">{{ slotProps.data.order_packages * slotProps.data["Package size"] }}</span>
            </template>
        </Column>
        <Column field="Main category" header="Main category" />
        <Column field="Sub category" header="Sub category" />
        <Column field="Display type" header="Display type" />
        <template #expansion="slotProps">
            <div class="p-4 bg-blue-100 rounded">
                <span hidden>{{ slotProps.data['Item no.'] }}</span>
                <Button label="Search for reference products" icon="pi pi-search" class="w-auto" @click="product_search(slotProps.data['Item no.'], slotProps.data['Item name'])" />
                <DataTable :value="slotProps.data['matches']" @row-click="product_details" :rows="50" responsiveLayout="scroll" :rowHover="true" class="rounded mt-2">
                    <Column style="width: 100px">
                        <template #body="slotProps">
                            <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['No_'] + '.jpg'" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['No_'] + '.jpg\' />', escape: false}" class="w-3rem h-3rem shadow-2" />
                        </template>
                    </Column>
                    <Column field="id" header="Item no." sortable style="width: 150px">
                        <template #body="slotProps">
                            <span class="font-bold">{{ slotProps.data.No_ }}</span><i @click="copy_to_clipboard(slotProps.data.No_)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
                        </template>
                    </Column>
                    <Column field="Description" header="Description" />
                    <Column field="Description 2" header="Description 2" />
                    <Column field="Description in Local Language" header="Description (DE)" />
                    <Column field="Unit Price" header="Unit price">
                        <template #body="slotProps">
                            <span>{{ format_currency(slotProps.data["Unit Price"]) }}</span>
                        </template>
                    </Column>
                    <Column field="Unit Cost" header="Unit cost">
                        <template #body="slotProps">
                            <span>{{ format_currency(slotProps.data["Unit Cost"]) }}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-times" severity="danger" rounded aria-label="Cancel" v-tooltip.top="'Remove' + slotProps.data.No_" @click="delete_match(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </DataTable>

    <Dialog v-model:visible="calculatePurchaseDialog" :style="{width: '80%'}" header="Purchase calculation" :modal="true" class="p-fluid z-5">
        <div class="flex" style="position: sticky; top: 0">
            <div class="mt-2 shadow-1 bg-blue-50 p-2 border-2 border-blue-200 border-round">
                <div>
                    <div class="flex-wrap flex align-items-center justify-content-start"> 
                        <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + current_purchase_item_no + '.jpg'" class="w-3rem h-3rem shadow-2 mr-2" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + current_purchase_item_no + '.jpg\' />', escape: false}" />
                        <span class="font-bold mr-2">{{ current_purchase_item_name }}:</span>
                        <span class="font-bold text-red-800 mr-1">{{ format_currency(current_purchase_item_recommended_price) }}</span>
                        <span>(rec. sale price)</span>
                    </div>
                    <div class="flex-wrap flex align-items-center justify-content-start">
                        <span class="text-xl font-bold mr-2">Total packages:</span>
                        <span class="text-xl font-bold text-red-800 mr-1">{{ total_packages }}</span>
                        <span class="text-xl mr-2">({{ total_units }} Units)</span>
                        <span class="text-xl font-bold mr-2">Total purchase:</span>
                        <span class="text-xl font-bold text-red-800 mr-1">{{ format_currency(total_units * current_purchase_item_recommended_price) }}</span>
                        <span>(rec. sale price)</span>
                        <Button label="Save changes" icon="pi pi-save" class="w-auto ml-3" @click="save_purchase_amount()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="grid m-0 mt-3">
            <div class="col-2 font-bold bg-blue-50 m-0 p-2 border-bottom-2 border-right-1 border-blue-200">
                Store
            </div>
            <div class="col-7 font-bold bg-blue-50 m-0 p-2 border-bottom-2 border-right-1 border-blue-200">
                Reference Item
            </div>
            <div class="col-3 font-bold bg-blue-50 m-0 p-2 border-bottom-2 border-right-1 border-blue-200">
                <span class="mr-4">Purchase</span><span @click="check_all_boxes()" class="text-primary cursor-pointer"><i class="pi pi-check mr-1" />Check all</span>
            </div>
        </div>
        <div v-for="(store, key) in stores" :key="key" class="grid m-0">
            <div class="col-2 font-bold bg-green-50 m-0 p-2 border-bottom-2 border-right-1 border-blue-200">
                {{store.id}} ({{store.name}})
            </div>
            <div class="col-7 grid-nogutter p-2 border-bottom-2 border-right-1 border-blue-200">
                <div class="grid m-0">
                    <div class="col-1 font-bold bg-purple-50 m-0 p-2 border-bottom-1 border-right-1 border-blue-200">
                        Item #
                    </div>
                    <div class="col-4 font-bold bg-purple-50 m-0 p-2 border-bottom-1 border-right-1 border-blue-200">
                        Runtime
                    </div>
                    <div class="col-3 font-bold bg-purple-50 m-0 p-2 border-bottom-1 border-right-1 border-blue-200">
                        Sales
                    </div>
                    <div class="col-1 font-bold bg-purple-50 m-0 p-2 border-bottom-1 border-right-1 border-blue-200">
                        Average
                    </div>
                    <div class="col-3 font-bold bg-purple-50 m-0 p-2 border-bottom-1 border-right-1 border-blue-200">
                        Recommendation
                    </div>
                </div>
                <div v-for="(item, key2) in store['references']" :key="key2" class="grid m-0">
                    <div class="col-1 m-0 p-2 border-bottom-1 border-right-1 border-blue-200 text-xs">
                        <Tag :value="item" class="mr-2 bg-primary text-xs cursor-pointer" @click="product_details({data: {'No_': item}})" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + item + '.jpg\' />', escape: false}" />
                        <span>{{ format_currency(store['reference_price'][key2]) }}</span>
                    </div>
                    <div class="col-4 m-0 p-2 border-bottom-1 border-right-1 border-blue-200 text-sm">
                        <span v-if="store['from_date'][key2]" class="mr-2">{{ store['from_date'][key2]}} - {{ store['to_date'][key2]}} ({{ store['sale_runtime'][key2] - store['rest_days'][key2] }} Tage)</span>
                        <span v-if="store['from_date'][key2]" class="mr-2">{{ store['runtime_percent'][key2] }}%</span>
                        <span v-if="!store['from_date'][key2]">-</span>
                        <span v-if="store['days_til_first_sale'][key2]" class="text-red-700">fs: {{ store['days_til_first_sale'][key2] }} days</span>
                    </div>
                    <div class="col-3 m-0 p-2 border-bottom-1 border-right-1 border-blue-200 text-sm">
                        <span v-if="store['sale_volume'][key2]">{{ store['sale_volume'][key2] }} ({{ format_currency(store['sale_amount'][key2]) }})</span>
                        <span v-if="!store['sale_volume'][key2]">-</span>
                        <span v-if="store['soldout'][key2] && store['sale_volume'][key2]" class="text-green-800 ml-2">Sold out!</span>
                        <span v-if="!store['soldout'][key2] && store['sale_volume'][key2]" class="text-red-800 ml-2">{{ store['volume_rest'][key2] }} leftover</span>
                    </div>
                    <div class="col-1 m-0 p-2 border-bottom-1 border-right-1 border-blue-200 text-sm">
                        <span v-if="store['volume_average'][key2]">{{ store['volume_average'][key2] }} Pcs/Day</span>
                        <span v-if="!store['volume_average'][key2]">-</span>
                    </div>
                    <div class="col-3 m-0 p-2 border-bottom-1 border-right-1 border-blue-200 text-sm">
                        <span v-if="store['recommended_volume'][key2]" class="mr-2">{{ store['recommended_volume'][key2] }} Stk</span>
                        <span v-if="store['recommended_volume'][key2]" class="font-bold">({{ Math.ceil(store['recommended_volume'][key2] / store['packaging']) }} packages)</span>
                        <span v-if="!store['recommended_volume'][key2]">-</span>
                        <a v-if="store['recommended_volume'][key2]" href="#" @click="purchase_amount[store.id] = Math.ceil(store['recommended_volume'][key2] / store['packaging'])" class="text-primary ml-2 font-bold">adopt ►</a>
                    </div>
                </div>
            </div>
            <div class="col-3 m-0 p-2 border-bottom-1 border-right-1 border-blue-200">
                <div class="grid m-0">
                    <div class="col-4">
                        <div class="w-full">
                            <span class="font-bold">Packages</span>
                        </div>
                        <div class="w-full mt-1">
                            <InputNumber @update:modelValue="purchase_amount_change()" v-model="purchase_amount[store.id]" showButtons class="neuraxis_inputnumber w-full" />
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="w-full">
                            <span class="font-bold">Units</span>
                        </div>
                        <div class="w-full mt-1">
                            <span class="text-red-800 text-3xl font-bold">{{ purchase_amount[store.id] * store['packaging'] }}</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="w-full">
                            <span class="font-bold">Check</span>
                        </div>
                        <div class="w-full mt-2">
                            <ToggleButton v-model="purchase_amount_validation[store.id]" onLabel="OK" offLabel="No" onIcon="pi pi-check" offIcon="pi pi-times" class="w-auto" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>

    <Sidebar v-model:visible="searchProductsDialog" position="left" style="width: 80%">
        <TabView>
            <TabPanel header="Product Search">
                <div class="col-12 formgrid grid">
                    <div class="col-4">
                        <InputText v-model="search_term" placeholder="Search" class="w-full" />
                    </div>
                    <div class="col-2">
                        <Button label="Search" icon="pi pi-search" class="w-full" @click="manual_search(search_term)" />
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <DataTable :value="search_results" class="rounded mt-2" @row-click="product_details" responsiveLayout="scroll" :rowHover="true" :rows="200" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column style="width: 100px">
                            <template #body="slotProps">
                                <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['No_'] + '.jpg'" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['No_'] + '.jpg\' />', escape: false}" class="w-3rem h-3rem shadow-2" />
                            </template>
                        </Column>
                        <Column field="id" header="Item no." sortable style="width: 150px">
                            <template #body="slotProps">
                                <span class="font-bold">{{ slotProps.data.No_ }}</span>
                            </template>
                        </Column>
                        <Column field="Description" header="Description" />
                        <Column field="Description 2" header="Description 2" />
                        <Column field="Description in Local Language" header="Description (DE)" />
                        <Column field="volume" header="Sale">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.volume }} / {{ slotProps.data.stock }}</span>
                            </template>
                        </Column>
                        <Column field="Revenue" header="Revenue">
                            <template #body="slotProps">
                                <span>{{ format_currency(slotProps.data["revenue"]) }}</span>
                            </template>
                        </Column>
                        <Column field="Unit Price" header="Unit price">
                            <template #body="slotProps">
                                <span>{{ format_currency(slotProps.data["Unit Price"]) }}</span>
                            </template>
                        </Column>
                        <Column field="Unit Cost" header="Unit cost">
                            <template #body="slotProps">
                                <span class="mr-1">{{ format_currency(slotProps.data["Unit Cost"]) }}</span> <span class="text-green-800">(+{{ format_currency(slotProps.data["revenue"] - (slotProps.data.volume * slotProps.data["Unit Cost"])) }})</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-plus" severity="success" rounded aria-label="Cancel" v-tooltip.top="'Add to reference items'" @click="add_product(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel header="Campaign explorer">
                <div class="col-12 formgrid grid">
                    <div class="col-12 formgrid grid" v-if="campaign_report_data">
                        <Toolbar>
                            <template #start>
                                <span class="p-input-icon-left mr-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filter_campaign_report['global'].value" placeholder="Suche" style="width: 300px" />
                                </span>
                                <Dropdown v-model="selected_campaign" :options="campaigns" optionLabel="name" optionValue="id" placeholder="Select a campaign" class="w-auto" @change="load_campaign" />
                            </template>
                        </Toolbar>
                        <DataTable class="mt-3" v-model:filters="filter_campaign_report" :value="campaign_report_data.items" :rows="100" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[100,400,800]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                            <Column style="width: 100px">
                                <template #body="slotProps">
                                    <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['Item no.'] + '.jpg'" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + slotProps.data['Item no.'] + '.jpg\' />', escape: false}" class="w-3rem h-3rem shadow-2" />
                                </template>
                            </Column>
                            <Column field="Campaign" header="Campaign" sortable>
                                <template #body="slotProps">
                                    <span >{{slotProps.data.Campaign}}</span>
                                </template>
                            </Column>
                            <Column field="Item no." header="Item no." sortable>
                                <template #body="slotProps">
                                    <span class="font-bold mr-3">{{slotProps.data["Item no."]}}</span>
                                </template>
                            </Column>
                            <Column field="Item name" header="Item name" sortable>
                                <template #body="slotProps">
                                    <span class="mr-2">{{slotProps.data["Item name"]}}</span>
                                    <span>({{format_currency(slotProps.data["price_unit"])}})</span>
                                </template>
                            </Column>
                            <Column field="Main category" header="Main category" sortable>
                                <template #body="slotProps">
                                    <span>{{slotProps.data["Main category"]}}</span>
                                </template>
                            </Column>
                            <Column field="Sub category" header="Sub category" sortable>
                                <template #body="slotProps">
                                    <span>{{slotProps.data["Sub category"]}}</span>
                                </template>
                            </Column>
                            <Column field="Display type" header="Display type" sortable>
                                <template #body="slotProps">
                                    <span>{{slotProps.data["Display type"]}}</span>
                                </template>
                            </Column>
                            <Column field="total_profit2" header="Profit" sortable>
                                <template #body="slotProps">
                                    <span>{{format_currency(slotProps.data["total_profit2"])}}</span>
                                </template>
                            </Column>
                            <Column field="total_purchase_quantity" header="Purchased" sortable>
                                <template #body="slotProps">
                                    <span>{{format_number(slotProps.data["total_purchase_quantity"])}}</span>
                                </template>
                            </Column>
                            <Column field="total_sales_quantity" header="Sold" sortable>
                                <template #body="slotProps">
                                    <span>{{format_number(slotProps.data["total_sales_quantity"])}}</span>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button icon="pi pi-plus" severity="success" rounded aria-label="Cancel" v-tooltip.top="'Add to reference items'" @click="add_campaign_product(slotProps.data['Item no.'])" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </Sidebar>

    <Sidebar v-model:visible="productDetailsDialog" position="right" style="width: 80rem">
        <div class="col-12 formgrid grid">
            <div class="col-9">
                <div class="col-12 formgrid grid">
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Artikel-Nr.
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span class="font-bold">{{product.No_}}</span>
                        <i @click="copy_to_clipboard(product.No_)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Beschreibung
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.Description}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Beschreibung (DE)
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product["Description in Local Language"]}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Einheit
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product[""]}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Preis
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product["Unit Price"]}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Einkauf Preis
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product["Unit Cost"]}}</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + product.No_ + '.jpg'" class="w-full shadow-2" />
                <div v-if="!product.No_" class="w-full h-full shadow-2 flex align-items-center justify-content-center">
                    <i class="pi pi-times" />
                </div>
            </div>
            <div class="field col-12 p-2 font-bold text-3xl mt-3">Verkaufsvolumen</div>
            <div class="col-12 formgrid grid">
                <div v-for="(volume, key) in product.volume_stores" :key="key" class="col-6 formgrid grid">
                    <div class="field col-8 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        <span class="font-bold mr-2">{{volume.id}}</span><span>({{volume.name}})</span>
                    </div>
                    <div class="field col-4 p-2 bproduct-solid bproduct-bottom-1 m-0">
                        <span class="font-bold mr-2">{{ volume.volume }}</span><span>({{ format_currency(volume.sales) }})</span>
                    </div>
                </div>
            </div>
            <div class="col-12 formgrid grid">
                <div class="col-6 formgrid grid">
                    <div class="field col-8 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        <span class="font-bold mr-2">Total:</span>
                    </div>
                    <div class="field col-4 p-2 bproduct-solid bproduct-bottom-1 m-0">
                        <span class="font-bold mr-2">{{ product.volume_total }}</span><span>({{ format_currency(product.sales_total) }})</span>
                    </div>
                </div>
                <div class="col-6 formgrid grid">
                    <div class="field col-8 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        <span class="font-bold mr-2">Gesamtzahl Tage:</span>
                    </div>
                    <div class="field col-4 p-2 bproduct-solid bproduct-bottom-1 m-0">
                        <span class="font-bold mr-2">{{ product.volume_days }}</span><span class="text-red-500">(μ: {{ Math.round(product.average_volume * 100) / 100 }})</span>
                    </div>
                </div>
            </div>
            <div class="field col-9 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                <span v-if="product.sales_total && product.turnover">{{product.sales_total}} <span class="text-green-500">({{ format_currency(product.turnover) }})</span></span>
            </div>
            <div class="field col-12 p-2">
                <TabView>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-line mr-2"></i>
                            <span class="mr-3">Volumen</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-line mr-2"></i>
                            <span class="mr-3">Lager</span>
                        </template>
                        <Chart type="line" :data="stock_ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-bar mr-2"></i>
                            <span class="mr-3">Umsatz</span>
                        </template>
                        <Chart type="bar" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-money-bill mr-2"></i>
                            <span class="mr-3">Gewinn (Netto)</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-pie mr-2"></i>
                            <span class="mr-3">Sales % (Global)</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-pie mr-2"></i>
                            <span class="mr-3">Proffit % (Global)</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                </TabView>
            </div>
            <div class="field col-12 p-2 font-bold text-3xl mt-3">Lager</div>
            <div class="col-12 formgrid grid">
                <div v-for="(stock, key) in product.stores" :key="key" class="col-6 formgrid grid">
                    <div class="field col-8 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        <span class="font-bold mr-2">{{stock.id}}</span><span>({{stock.name}})</span>
                    </div>
                    <div class="field col-4 p-2 bproduct-solid bproduct-bottom-1 m-0">
                        <span class="font-bold mr-2">{{ stock.stock }}</span><span>({{ format_currency(stock.stock_value) }})</span>
                    </div>
                </div>
            </div>
            <div class="field col-9 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                <span v-if="product.stock">{{product.stock}}</span>
            </div>
        </div>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>