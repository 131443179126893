<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_products = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const products = ref([])
const product = ref()
const details = ref()
const details_index = ref()
const productDetailsDialog = ref(false)

const ledger = ref()
const chartOptions = ref()

const stores = ref([])

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    ledger.value = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novemver', 'December'],
        datasets: [
            {
                label: '2023',
                data: [65, 59, 80, 81, 56, 55, 40, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                tension: 0.4
            },
            {
                label: '2022',
                data: [28, 48, 40, 19, 86, 27, 90, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: documentStyle.getPropertyValue('--pink-500'),
                tension: 0.4
            }
        ]
    }
    ledger.value["datasets"] = product.value["ledger"]
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    }
}

onMounted( async () => {
    get_products()
})

const get_products = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-products')
    .then(response => {
        console.log(response.data)
        products.value = response.data
        loader.value = false
    })
}

const new_product = () => {
    console.log("new_product")
}

const product_details = (e) => {
    loader.value = true
    details.value = e.data
    details_index.value = e.index
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-product', {id: e.data.id})
    .then(response => {
        product.value = response.data
        console.log(product.value)
        loader.value = false
        productDetailsDialog.value = true
        chartOptions.value = setChartOptions();
    })
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + String(Number(value.substring(11,13)) + 1).padStart(2,0) + value.substring(13,16)
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}


</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_products['global'].value" placeholder="Suche" style="width: 300px" />
                    </span>
                </template>
                <template #end>
                    <Button label="Neues Projekt" icon="pi pi-plus" class="p-button" @click="new_product" />
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:filters="filter_products" :value="products" @row-click="product_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="id" header="Artikel-Nr" sortable style="width: 150px">
                <template #body="slotProps">
                    <Chip class="pl-0 pr-3 mr-2">
                        <span class="bg-primary bproduct-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                        <span class="ml-2 font-medium">{{slotProps.data.id}}</span>
                    </Chip>
                </template>
            </Column>
            <Column style="width: 100px">
                <template #body="slotProps">
                    <img v-if="slotProps.data.image_url" :src="slotProps.data.image_url" class="w-3rem h-3rem shadow-2" />
                    <div v-if="!slotProps.data.image_url" class="w-3rem h-3rem shadow-2 flex align-items-center justify-content-center">
                        <i class="pi pi-times" />
                    </div>
                </template>
            </Column>
            <Column field="description" header="Beschreibung" sortable />
            <Column field="description_de" header="Beschreibung (DE)" sortable />
            <Column field="measure_unit" header="Lagerbestand">
                <template #body="slotProps">
                    <span class="font-medium">x {{slotProps.data.measure_unit}}</span>
                </template>
            </Column>
            <Column field="cost_unit" header="Preis">
                <template #body="slotProps">
                    <span class="font-medium mr-2">{{format_currency(slotProps.data.price_unit)}} ({{format_currency_without_prefix(slotProps.data.cost_unit)}})</span>
                    <span :class="(Number(slotProps.data.price_unit) - Number(slotProps.data.cost_unit)) > 0 ? 'text-green-500' : 'text-red-500'">{{ format_currency_without_prefix(Number(slotProps.data.price_unit) - Number(slotProps.data.cost_unit)) }}</span>
                </template>
            </Column>
        </DataTable>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="productDetailsDialog" position="right" style="width: 80rem">
        <div class="col-12 formgrid grid">
            <div class="col-9">
                <div class="col-12 formgrid grid">
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Artikel-Nr.
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span class="font-bold">{{product.id}}</span>
                        <i @click="copy_to_clipboard(product.id)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Beschreibung
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.description}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Beschreibung (DE)
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.description_de}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Einheit
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.measure_unit}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Preis
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.price_unit}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Einkauf Preis
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.cost_unit}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Preis (angepasst)
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.cost_adjusted}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Lieferanten ID
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.vendor_id}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Beschreibung Suche
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.description_search}}</span>
                    </div>
                    <div class="field col-5 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                        Steuer-Kategorie
                    </div>
                    <div class="field col-7 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                        <span>{{product.vat_prod}}</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <Image v-if="product.image_url" :src="product.image_url" class="w-full h-full shadow-2" preview />
                <div v-if="!product.image_url" class="w-full h-full shadow-2 flex align-items-center justify-content-center">
                    <i class="pi pi-times" />
                </div>
            </div>
            <div class="field col-12 p-2 font-bold text-3xl mt-3">Verkaufszahlen</div>
            <div class="field col-3 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                Lugano (CH01020)
            </div>
            <div class="field col-9 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                <span v-if="product.sales_total && product.turnover">{{product.sales_total}} <span class="text-green-500">({{ format_currency(product.turnover) }})</span></span>
            </div>
            <div class="field col-12 p-2">
                <TabView>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-line mr-2"></i>
                            <span class="mr-3">Ledgers</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-bar mr-2"></i>
                            <span class="mr-3">Umsatz</span>
                        </template>
                        <Chart type="bar" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-money-bill mr-2"></i>
                            <span class="mr-3">Gewinn (Netto)</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-pie mr-2"></i>
                            <span class="mr-3">Sales % (Global)</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-chart-pie mr-2"></i>
                            <span class="mr-3">Proffit % (Global)</span>
                        </template>
                        <Chart type="line" :data="ledger" :options="chartOptions" class="h-30rem" />
                    </TabPanel>
                </TabView>
            </div>
            <div class="field col-12 p-2 font-bold text-3xl mt-3">Lager</div>
            <div class="field col-3 p-2 font-bold bproduct-blue-100 bproduct-solid bproduct-bottom-1 bg-blue-50 m-0">
                Lugano (CH01020)
            </div>
            <div class="field col-9 p-2 bproduct-blue-100 bproduct-solid bproduct-bottom-1 m-0">
                <span v-if="product.stock">{{product.stock}}</span>
            </div>
        </div>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>