<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import Database from "./Admin-Datahub-Db.vue"
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <div class="p-5">
        <div>
            <Toolbar class="my-3">
                <template #start>
                    <RouterLink to="/dashboard"><Button label="Zurück zum Dashboard" icon="pi pi-angle-double-left" class="mr-2" /></RouterLink>
                </template>
            </Toolbar>
        </div>
        <div class="p-fluid formgrid grid mt-3">
            <TabView class="col-12" ref="tabview4">
                <TabPanel>
                <template #header>
                    <i class="pi pi-database mr-2"></i>
                    <span class="mr-3">Database</span>
                </template>
                <Database />
                </TabPanel>
                <TabPanel>
                <template #header>
                    <i class="pi pi-comments mr-2"></i>
                    <span class="mr-3">LLM</span>
                </template>
                yeah boi
                </TabPanel>
                <TabPanel>
                <template #header>
                    <i class="pi pi-comments mr-2"></i>
                    <span class="mr-3">Images</span>
                </template>
                yeah boi 2
                </TabPanel>
                <TabPanel>
                <template #header>
                    <i class="pi pi-comments mr-2"></i>
                    <span class="mr-3">Audio</span>
                </template>
                yeah boi 3
                </TabPanel>
                <TabPanel>
                <template #header>
                    <i class="pi pi-comments mr-2"></i>
                    <span class="mr-3">Video</span>
                </template>
                yeah boi 4
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>